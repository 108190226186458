import type { TableRow } from "@adaptive/design-system";
import { dotObject } from "@adaptive/design-system/utils";
import type { BudgetLineItems } from "@api/jobs";
import { sum } from "@utils/sum";

export const isNumber = (value: unknown): value is number =>
  typeof value === "number" && !isNaN(value);

export const getIsNullOrUndefined = (value: any): boolean =>
  value === null || value === undefined;

type GetPreferredCostBudgetAmountParams = {
  [key: string]: any;
  builderAmount?: number;
  changeAmount?: number;
  builderRevisedAmount?: number;
  ownersRevisedAmount?: number;
};

export const getPreferredCostBudgetAmount = (
  row?: GetPreferredCostBudgetAmountParams
): number => {
  if (!row) return 0;

  const { builderAmount, builderRevisedAmount } = row;

  if (!getIsNullOrUndefined(builderRevisedAmount)) {
    return builderRevisedAmount as number;
  }

  if (!getIsNullOrUndefined(builderAmount)) {
    return builderAmount as number;
  }

  return 0;
};

type GetPreferredRevenueBudgetAmountParams = {
  [key: string]: any;
  builderAmount?: number;
  changeAmount?: number;
  builderRevisedAmount?: number;
  ownersRevisedAmount?: number;
};

export const getPreferredRevenueBudgetAmount = (
  row: GetPreferredRevenueBudgetAmountParams
): number => {
  if (!row) return 0;

  const {
    builderAmount,
    builderRevisedAmount,
    ownersAmount,
    ownersRevisedAmount,
  } = row;

  if (
    !getIsNullOrUndefined(ownersRevisedAmount) &&
    isNumber(ownersRevisedAmount)
  ) {
    return ownersRevisedAmount;
  }

  if (!getIsNullOrUndefined(ownersAmount) && isNumber(ownersAmount)) {
    return ownersAmount;
  }

  if (
    !getIsNullOrUndefined(builderRevisedAmount) &&
    isNumber(builderRevisedAmount)
  ) {
    return builderRevisedAmount;
  }

  if (!getIsNullOrUndefined(builderAmount) && isNumber(builderAmount)) {
    return builderAmount;
  }

  return 0;
};

export const getBudgetRemaining = (row: TableRow<BudgetLineItems>) =>
  (row.data?.length ? row.data : [row]).reduce(
    (acc, line) =>
      sum(acc, sum(getPreferredCostBudgetAmount(line), -line.spent)),
    0
  );

type GetBudgetAmountParams = {
  type: "cost" | "revenue";
  ownersAmount: number;
  builderAmount: number;
  ownersAmountEnabled: boolean;
  ownersRevisedAmount?: number;
  builderRevisedAmount?: number;
  changeTrackingEnabled: boolean;
};

export const getBudgetAmount = ({
  type,
  ownersAmount,
  builderAmount,
  ownersRevisedAmount,
  ownersAmountEnabled,
  builderRevisedAmount,
  changeTrackingEnabled,
}: GetBudgetAmountParams) => {
  if (ownersAmountEnabled && type === "revenue") {
    return (changeTrackingEnabled ? ownersRevisedAmount : ownersAmount) || 0;
  }

  return (changeTrackingEnabled ? builderRevisedAmount : builderAmount) || 0;
};

type GetRemainingParams = {
  spent: number;
  builderAmount: number;
  ownersAmount: number;
  invoicedAmount: number;
  ownersAmountEnabled: boolean;
  builderRevisedAmount?: number;
  changeTrackingEnabled: boolean;
};

export const getRemaining = (row: GetRemainingParams) => {
  const preferredRevenueBudget = getBudgetAmount({ ...row, type: "revenue" });
  const preferredCostBudget = getBudgetAmount({ ...row, type: "cost" });
  const builderRemainingAmount = sum(preferredCostBudget, -row.spent);
  const invoicedRemainingAmount = sum(
    preferredRevenueBudget,
    -row.invoicedAmount
  );

  return { builderRemainingAmount, invoicedRemainingAmount };
};

export const sortByReference = <Data extends Record<string, unknown>[]>({
  key,
  original,
  reference,
}: {
  key: string;
  original: Data;
  reference: Data;
}) =>
  [...original].sort((a, b) => {
    const indexA = reference.findIndex(
      (item) => dotObject.get(item, key) === dotObject.get(a, key)
    );
    const indexB = reference.findIndex(
      (item) => dotObject.get(item, key) === dotObject.get(b, key)
    );
    return indexA === -1 || indexB === -1 ? 0 : indexA - indexB;
  });
