import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { BankAccountV1 } from "@shared/api/bank-accounts";
import { getUserByProfile } from "@src/shared/utils/get-user-by-profile";
import {
  type KeysToCamelCase,
  transformKeysToCamelCase,
} from "@utils/schema/converters";

import { RootState } from "../types";

import { loadClients, switchClient } from "./thunks";
import type { NotificationCategory } from "./types";
export type Permission = {
  id: number;
  name: string;
};

export type Role = {
  id: number;
  name: string;
  permissions: Permission[];
};

export type Realm = {
  id: number;
  url: string;
  company_name: string;
};

export type ClientSettings = {
  can_accounts_link_to_lines_desktop: boolean;
  can_items_link_to_lines_desktop: boolean;
  can_accounts_link_to_lines_mobile: boolean;
  can_items_link_to_lines_mobile: boolean;
  is_billable_default_bill: boolean;
  is_billable_default_expense: boolean;
  categories_enabled: boolean;
  change_tracking_enabled: boolean;
  external_budget_enabled: boolean;
  external_revised_budget_enabled: boolean;
  draw_exports_v2_enabled: boolean;
  reset_approval_on_edit: boolean;
  can_set_qb_class: boolean;
  can_set_location: boolean;
  card_feed_enabled: boolean;
  can_delay_payment_until_lien_waiver_is_signed: boolean;
  can_manage_lien_waivers: boolean;
  can_set_bill_payment_minimum: boolean;
  bill_payment_minimum_for_lien_waiver: number;
  should_collapse_bill_lines: boolean;
  should_collapse_expense_lines: boolean;
  should_show_all_customers_in_dropdowns: boolean;
  sales_tax_account?: { url: string };
  notification_center_enabled: boolean;
  sales_tax_item?: { url: string };
  explo_token?: string;
  default_reviewer?: {
    id: number;
    url: string;
    email: string;
    full_name: string;
  };
  explo_dashboard_ids: string[];
  branding_logo?: string;
  explo_report_builder_ids: string[];
  is_sales_tax_account_or_item_setting_enabled: boolean;
  fidel_enabled: boolean;
  external_comments_enabled: boolean;
  capital_os_enabled: boolean;
  adaptive_pos_enabled: boolean;
  notification_settings: boolean;
  ai_chat_enabled: boolean;
  should_remind_for_expenses: boolean;
  reminder_period_hours: number | null;
};

//TODO infer this from zod schema
export type Client = {
  id: string;
  name: string;
  email: string;
  role: Role;
  realm: Realm;
  settings: ClientSettings;
  bank_accounts: BankAccountV1[];
  integration_type: "QBO" | "QBDT";
};

//TODO infer this from zod schema
// we already have something close as a comment author in expenses
type SnakeCasedUser = {
  id: string;
  url: string;
  role: Role;
  full_name: string;
  hash: string;
  type: "external" | "standard";
  email: string;
  created: string;
  realm_id: number;
  profiles: { realm_id: number; full_name: string }[];
  phone_number?: string | null;
  two_factor_verified?: boolean;
  phone_number_verified?: boolean;
  notifications: { notification: string; type: string }[];
};

type CamelCasedUser = KeysToCamelCase<SnakeCasedUser>;

export type User = (SnakeCasedUser | CamelCasedUser) & {
  [keys: string]: any;
};

export type Person = {
  id: string;
  full_name: string;
  email: string;
  phone_number?: string;
  role: { id: string; name: string; url: string; description: string };
  is_user: boolean;
};

export type UserState = {
  user: User | Record<string, never>;
  clients: Client[];
  people: Person[];
  notificationCategories: NotificationCategory[];
  currentClient: Client | null | undefined;
  isChatVisible: boolean;
};

const initialState: UserState = {
  user: {},
  clients: [],
  people: [],
  notificationCategories: [
    {
      id: 1,
      name: "Receipts",
      notifications: [
        {
          id: "REVIEW_REQUIRED",
          name: "Review required",
          description: "Get alerted when a receipt needs your review.",
          types: ["EMAIL", "SMS"],
        },
        {
          id: "PURCHASE_MADE",
          name: "Purchase made",
          description:
            "Get alerted when you buy something with an enrolled card that requires a receipt.",
          types: ["SMS", "PUSH"],
        },
        ...(window.ASSIGNEE_ENABLED
          ? ([
              {
                id: "EXPENSE_ASSIGNED",
                name: "Assigned",
                description:
                  "Get alerted when someone assigns a receipt to you.",
                types: ["EMAIL", "SMS", "PUSH"],
              },
            ] as UserState["notificationCategories"][0]["notifications"])
          : []),
      ],
    },
    {
      id: 2,
      name: "Bills",
      notifications: [
        {
          id: "APPROVAL_REQUIRED",
          name: "Approval required",
          description: "Get alerted when a bill needs your approval.",
          types: ["EMAIL", "SMS"],
        },
        {
          id: "READY_FOR_PAYMENT",
          name: "Ready for payment",
          description: "Get alerted when a bill is ready to be paid.",
          types: ["EMAIL"],
        },
      ],
    },
    {
      id: 3,
      name: "Comments",
      notifications: [
        {
          id: "COMMENT_MADE",
          name: "Comment made",
          description:
            "Get alerted when anyone comments on a transaction you’re involved in.",
          types: ["EMAIL", "SMS", "PUSH"],
        },
        {
          id: "COMMENT_MENTIONED",
          name: "Comment mention",
          description: "Get alerted if you are tagged in a comment.",
          types: ["EMAIL", "SMS", "PUSH"],
        },
      ],
    },
  ],
  isChatVisible: false,
  currentClient: null,
};

if (window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED) {
  initialState.notificationCategories.push({
    id: 4,
    name: "Purchase orders",
    notifications: [
      {
        id: "VENDOR_PO_SIGNATURE_PROVIDED",
        name: "Signature received",
        description:
          "Get alerted when a vendor signs a purchase order request that you sent.",
        types: ["EMAIL", "SMS"],
      },
    ],
  });
}

const LOCAL_STORAGE_KEY = "currentClient";

export const getCurrentClient = (clients: Client[]) => {
  const storedClient = clients.find(
    (client) => client.id === localStorage.getItem(LOCAL_STORAGE_KEY)
  );

  if (!storedClient) localStorage.removeItem(LOCAL_STORAGE_KEY);

  return storedClient ?? clients[0];
};

const selectUserState = (state: RootState) => state.user;

export const userSelector = createSelector(
  selectUserState,
  (user) => ({ ...user.user, ...transformKeysToCamelCase(user.user) }) as User
);

export const notificationCategoriesSelector = createSelector(
  selectUserState,
  (state) => state.notificationCategories
);

export const peopleSelector = createSelector(
  selectUserState,
  (state) => state.people
);

export const currentClientSelector = createSelector(
  selectUserState,
  (state) => state.currentClient
);

export const profiledUserSelector = createSelector(
  [userSelector, currentClientSelector],
  (user, currentClient) =>
    getUserByProfile(user, currentClient?.realm.id) || user
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    partialUpdateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    updatePeople: (state, action) => {
      state.people = action.payload;
    },
    removePerson: (state, action) => {
      state.people = state.people.filter(
        (person) => person.id !== action.payload
      );
    },
    switchRole: (state, action) => {
      state.people = state.people.map((person) => {
        if (person.role.url === action.payload.role.url) {
          person.role = action.payload.newRole;
        }
        return person;
      });
    },
    updateClients: (state, action) => {
      state.clients = action.payload;
      state.currentClient = getCurrentClient(state.clients);
    },
    updatePersonData: (state, action) => {
      state.people = state.people.map((person) => {
        if (person.id === action.payload.userId) {
          person.role = {
            ...person.role,
            url: action.payload.role,
          };
        }
        return person;
      });
    },
    toggleChatVisibility: (state, action) => {
      state.isChatVisible = action.payload ?? !state.isChatVisible;
    },
    updateTwoFactorAuthStatus: (state, action) => {
      state.user.two_factor_verified = action.payload;
      state.user.phone_number_verified = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(switchClient.fulfilled, (state, action) => {
      const client = action.payload;
      if (!client) return;

      state.currentClient = client;
      localStorage.setItem(LOCAL_STORAGE_KEY, client.id);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(loadClients.fulfilled, (state, action) => {
      (() => {
        /* TODO this is a no-op, since we're dispatching updateClients from within the thunk but should probably do something :) */
      })();
    });
  },
});

export const {
  updateUser,
  partialUpdateUser,
  updateClients,
  toggleChatVisibility,
  updatePeople,
  updatePersonData,
  updateTwoFactorAuthStatus,
  switchRole,
  removePerson,
} = userSlice.actions;

export const { reducer } = userSlice;

export { switchClient } from "./thunks";
