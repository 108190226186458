import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  DateField,
  Flex,
  Icon,
  Text,
  TextField,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { formatDate, isEqual, parseDate } from "@adaptive/design-system/utils";
import { Comments } from "@components/comments";
import { Form, SelectVendor } from "@components/form";
import { Items } from "@components/items";
import { useIntegrationType } from "@hooks/use-integration-type";
import { useVendorsSimplified } from "@hooks/use-vendors-simplified";
import { usePurchaseOrderDrawer } from "@shared/store/ui";
import { useClientSettings } from "@src/shared/store/user";
import {
  addLine,
  recordLineUpdate,
  recordPurchaseOrderUpdate,
  removeLine,
  updateTotalFromLines,
} from "@store/purchaseOrderSlice";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { transformKeysToCamelCase } from "@utils/schema/converters";

import { selectPurchaseOrder } from "./purchase-orders-selectors";

const vendorSelector = (state) =>
  transformKeysToCamelCase(state.purchaseOrder?.purchaseOrder?.vendor);

const commentsSelector = (state) => ({
  comments: state.purchaseOrder?.purchaseOrder?.comments.map((comment) => ({
    ...comment,
    hasExternalMention: comment.has_external_mention,
    createdAt: new Date(comment.created_at),
    timelineEventType: comment.timeline_event_type,
    relatedVersions: comment.related_versions?.map((version) =>
      transformKeysToCamelCase(version)
    ),
    author: comment.author
      ? {
          ...comment.author,
          fullName: comment.author.full_name,
        }
      : null,
    attachable: comment.attachable,
    replies:
      comment.replies?.map((reply) => ({
        ...reply,
        hasExternalMention: reply.has_external_mention,
        createdAt:
          typeof reply.created_at === "string"
            ? new Date(reply.created_at)
            : reply.created_at,
        timelineEventType: reply.timeline_event_type,
        author: {
          ...reply.author,
          fullName: reply.author.full_name,
        },
      })) || [],
    ...(comment.diffs
      ? {
          diffs: comment.diffs.map((diff) => ({
            ...diff,
            oldValue: diff.old_value,
            newValue: diff.new_value,
          })),
        }
      : {}),
  })),
  url: state.purchaseOrder?.purchaseOrder?.url,
});

export const PurchaseOrderFormContent = ({
  onSave,
  permissions,
  linkedLines,
}) => {
  const { state } = usePurchaseOrderDrawer();

  const dispatch = useDispatch();

  const settings = useClientSettings();

  const purchaseOrder = useSelector(selectPurchaseOrder);

  const { data: vendors } = useVendorsSimplified();

  const integrationType = useIntegrationType();

  const vendor = useSelector(vendorSelector, isEqual);

  const preSelectedVendor = useMemo(
    () =>
      vendors.find(
        (item) => parseRefinementIdFromUrl(item.value) == state.vendorId
      ),
    [vendors, state.vendorId]
  );

  const hasLinkedBills =
    linkedLines.length > 0 ||
    purchaseOrder?.lines.some((line) => line.is_linked_to_bill);

  const data = useMemo(() => {
    return (purchaseOrder?.lines ?? [])
      .filter((line) => !("deleted" in line))
      .map((line) => {
        const amountValue = line.amount || 0;

        let costCodeValue = "";

        if (line.account?.url) {
          costCodeValue = !line.account?.display_name
            ? line.account.url
            : {
                label: line.account.display_name,
                value: line.account.url,
                groupLabel: "Account",
              };
        } else if (line.item?.url) {
          costCodeValue = !line.item?.display_name
            ? line.item.url
            : {
                label: line.item.display_name,
                value: line.item.url,
                groupLabel: "Cost code",
              };
        }

        const isLinkedToBill =
          linkedLines.some((linkedLine) => linkedLine == line.id) ||
          line.is_linked_to_bill;

        const jobCustomerValue =
          line.customer?.url && line.customer?.display_name
            ? { label: line.customer.display_name, value: line.customer.url }
            : line.customer?.url
              ? line.customer?.url
              : "";

        return {
          id: line.id,
          amount: {
            value: amountValue,
            required: true,
            errorMessage: amountValue === 0 ? "Amount should not be empty" : "",
          },
          extra: {
            label: "Open balance",
            value:
              line?.balance === null ? (
                <Flex as="span" align="center" gap="sm">
                  Save draft{" "}
                  <Tooltip
                    as={Icon}
                    size="sm"
                    name="info-circle"
                    color="neutral-800"
                    message={`Click "Save" below to update the Open balance`}
                  />
                </Flex>
              ) : (
                (line?.balance ?? 0)
              ),
            variant:
              line?.balance < 0 || line?.balance === null
                ? "warning"
                : "neutral",
          },
          description: {
            value: line.description,
            disabled: isLinkedToBill,
            helperMessage: isLinkedToBill
              ? "Not editable when linked to a Bill"
              : "",
          },
          ...(!permissions.isQbPo
            ? { closable: { checked: line.status === "Open" } }
            : {}),
          jobCustomer: {
            value: jobCustomerValue,
            disabled: isLinkedToBill,
            helperMessage: isLinkedToBill
              ? "Not editable when linked to a Bill"
              : "",
          },
          costCodeAccount: {
            value: costCodeValue,
            required: true,
            disabled: isLinkedToBill,
            errorMessage: !costCodeValue ? "Items must be attributed" : "",
            helperMessage: isLinkedToBill
              ? "Not editable when linked to a Bill"
              : "",
          },
        };
      });
  }, [linkedLines, permissions.isQbPo, purchaseOrder?.lines]);

  const componentsProps = useMemo(
    () => ({
      costCodeAccount: {
        accountFilters: {
          enabled: integrationType !== "QBDT",
          only_purchase_order_line_accounts: true,
        },
        costCodeFilters: {
          canItemsLinkToLinesDesktop: true,
        },
      },
      table: { headerSticky: { offset: -24 } },
    }),
    [integrationType]
  );

  const onAmountChange = useEvent((id, value) => {
    const item = purchaseOrder?.lines?.find((line) => line.id === id);

    if (!item || value === item.amount) return;

    dispatch(
      recordLineUpdate({ id, payload: { amount: value, balance: null } })
    );
    dispatch(updateTotalFromLines());
  });

  const onJobCustomerChange = useEvent((id, option) => {
    const customer = {
      display_name: option?.label ?? null,
      url: option?.value ?? null,
    };
    dispatch(recordLineUpdate({ id, payload: { customer } }));
  });

  const onClosableChange = useEvent((id, checked) => {
    const newStatus = checked ? "Open" : "Closed";
    const payload = { status: newStatus, force_close: !checked };
    dispatch(recordLineUpdate({ id, payload }));
  });

  const onAddComment = useEvent(
    ({ user, text, id, url, parentCommentUrl, hasExternalMention, files }) => {
      const newComment = {
        id,
        url,
        text,
        author: { ...user, full_name: user.fullName },
        created_at: new Date(),
        files,
        has_external_mention: hasExternalMention,
        timeline_event_type: "COMMENT",
      };

      dispatch(
        recordPurchaseOrderUpdate({
          comments: parentCommentUrl
            ? purchaseOrder.comments.map((comment) => {
                if (comment.url === parentCommentUrl) {
                  return {
                    ...comment,
                    replies: [...(comment.replies || []), newComment],
                  };
                }
                return comment;
              })
            : [...purchaseOrder.comments, newComment],
        })
      );
    }
  );

  const onCostCodeAccountChange = useEvent((id, option) => {
    const payload = {
      item: { display_name: null, url: null },
      account: { display_name: null, url: null },
      category: null,
    };

    if (option) {
      payload.category = option.groupLabel;

      if (payload.category === "Account") {
        payload.type = "AccountBasedExpenseLineDetail";
        payload.account = { display_name: option.label, url: option.value };
      } else {
        payload.type = "ItemBasedExpenseLineDetail";
        payload.item = { display_name: option.label, url: option.value };
      }
    }

    dispatch(recordLineUpdate({ id, payload }));
  });

  const onDescriptionChange = useEvent((id, value) => {
    dispatch(recordLineUpdate({ id, payload: { description: value } }));
  });

  const canRemoveLine = useCallback(
    (id) => {
      const line = purchaseOrder?.lines?.find((line) => line.id == id);

      if (!line) return false;

      const isLinkedToBill =
        linkedLines.some((linkedLine) => linkedLine == line.id) ||
        line.is_linked_to_bill;

      return !isLinkedToBill;
    },
    [linkedLines, purchaseOrder?.lines]
  );

  const onItemsRemove = useEvent((id) => {
    if (!canRemoveLine(id)) return false;

    dispatch(removeLine(id));
    dispatch(updateTotalFromLines());
  });

  const onItemsAdd = useEvent(() => {
    dispatch(addLine());
  });

  const mutateStrategy = {
    amount: onAmountChange,
    closable: onClosableChange,
    jobCustomer: onJobCustomerChange,
    description: onDescriptionChange,
    costCodeAccount: onCostCodeAccountChange,
  };

  const onItemsChange = useEvent(({ id, name, value }) => {
    const line = purchaseOrder?.lines?.find((line) => line.id == id);

    if (!line) return false;

    const isLinkedToBill =
      linkedLines.some((linkedLine) => linkedLine == line.id) ||
      line.is_linked_to_bill;

    if (["costCodeAccount", "jobCustomer"].includes(name) && isLinkedToBill) {
      return false;
    }

    mutateStrategy[name](id, value);
  });

  const isNewItem = useCallback((item) => typeof item.id !== "number", []);

  useEffect(() => {
    if (preSelectedVendor) {
      requestAnimationFrame(() => {
        dispatch(
          recordPurchaseOrderUpdate({
            vendor: {
              id: parseRefinementIdFromUrl(preSelectedVendor.value),
              url: preSelectedVendor.value,
              email: preSelectedVendor.email,
              display_name: preSelectedVendor.label,
            },
          })
        );
      });
    }
  }, [dispatch, preSelectedVendor]);

  return (
    <Flex gap="5xl" direction="column">
      <Form id="purchase-order" onSubmit={onSave} onEnterSubmit={onSave}>
        <Flex gap="5xl" direction="column">
          <Flex gap="xl" direction="column">
            <Text size="xl" weight="bold">
              Details
            </Text>
            <Card>
              <Flex direction="column" gap="sm">
                <SelectVendor
                  label="Vendor name"
                  required
                  placeholder="Select vendor name"
                  data-testid="purchase-order-vendor"
                  disabled={
                    !permissions.canEdit || hasLinkedBills || permissions.isQbPo
                  }
                  selector={vendorSelector}
                  helperMessage={
                    (settings.adaptivePosEnabled &&
                      permissions.canEdit &&
                      hasLinkedBills &&
                      purchaseOrder.type === "adaptive") ||
                    (!settings.adaptivePosEnabled && hasLinkedBills)
                      ? "Not editable when linked to a Bill"
                      : ""
                  }
                  onChange={(_, option) =>
                    dispatch(
                      recordPurchaseOrderUpdate({
                        vendor: {
                          url: option?.value,
                          email: option?.email,
                          display_name: option?.label,
                        },
                      })
                    )
                  }
                />

                <Flex gap="xl">
                  <TextField
                    disabled={!permissions.canEdit || permissions.isQbPo}
                    label="Purchase order number"
                    value={purchaseOrder.doc_number}
                    maxLength={20}
                    data-testid="po-number"
                    onChange={(value) =>
                      dispatch(recordPurchaseOrderUpdate({ doc_number: value }))
                    }
                  />

                  <DateField
                    label="Purchase order date"
                    disabled={!permissions.canEdit || permissions.isQbPo}
                    value={
                      purchaseOrder.date
                        ? parseDate(purchaseOrder.date, "yyyy-MM-dd")
                        : null
                    }
                    onChange={(date) => {
                      dispatch(
                        recordPurchaseOrderUpdate({
                          date: date ? formatDate(date, "yyyy-MM-dd") : null,
                        })
                      );
                    }}
                  />
                </Flex>
                <TextField
                  disabled={!permissions.canEdit || permissions.isQbPo}
                  label="Shipping address"
                  value={purchaseOrder.shipping_address}
                  messageVariant="absolute"
                  onChange={(value) =>
                    dispatch(
                      recordPurchaseOrderUpdate({ shipping_address: value })
                    )
                  }
                />
              </Flex>
            </Card>
          </Flex>

          <Items
            id="purchase-order-items"
            data={data}
            total={purchaseOrder?.total_amount ?? 0}
            onAdd={onItemsAdd}
            onChange={onItemsChange}
            onRemove={onItemsRemove}
            disabled={
              !permissions.canEdit || permissions.isQbPo
                ? true
                : {
                    removeLine: (line) => !canRemoveLine(line.id),
                  }
            }
            isNewItem={isNewItem}
            data-testid="purchase-order-items"
            componentsProps={componentsProps}
          />
        </Flex>
      </Form>

      {purchaseOrder.id ? (
        <Comments
          vendor={vendor}
          selector={commentsSelector}
          onAddComment={permissions.canComment ? onAddComment : undefined}
        />
      ) : null}
    </Flex>
  );
};
