import { useJobSettings } from "@src/jobs/hooks";

import { getBudgetAmount } from "../utils";

type UseBudgetAmountProps = {
  row: {
    [key: string]: any;
    builderAmount: number;
    builderRevisedAmount?: number;
    ownersAmount: number;
    ownersRevisedAmount?: number;
  };
  type: "cost" | "revenue";
};

export const useBudgetAmount = ({
  row,
  type,
}: UseBudgetAmountProps): number => {
  const { ownersAmountEnabled, changeTrackingEnabled } = useJobSettings();

  return getBudgetAmount({
    ...row,
    type,
    ownersAmountEnabled,
    changeTrackingEnabled,
  });
};
