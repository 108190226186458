import { useMemo } from "react";
import { BasePermissions, useUserInfo } from "@store/user";
import { useContextSelector } from "use-context-selector";

import { JobContext } from "./context";

export const useJobActions = () => ({
  invoiceCreate: useContextSelector(
    JobContext,
    (context) => context.invoiceCreate
  ),
  setBudgetSelectedLines: useContextSelector(
    JobContext,
    (context) => context.setBudgetSelectedLines
  ),
  setInvoiceSelectedDraws: useContextSelector(
    JobContext,
    (context) => context.setInvoiceSelectedDraws
  ),
  refetchJob: useContextSelector(JobContext, (context) => context.refetchJob),
});

export const useJobMarkups = () =>
  useContextSelector(JobContext, (context) => context.markups);

export const useJobMarkupsIsLoading = () =>
  useContextSelector(JobContext, (context) => context.markupsIsLoading);

export const useJobSettings = () =>
  useContextSelector(JobContext, (context) => context.settings);

export const useJobCategories = () =>
  useContextSelector(JobContext, (context) => context.categories);

export const useJobPermissions = () =>
  useContextSelector(JobContext, (context) => context.permissions);

export const useJobInvoiceIsLoading = () =>
  useContextSelector(JobContext, (context) => context.invoiceIsLoading);

export const useJobCategoriesIsLoading = () =>
  useContextSelector(JobContext, (context) => context.categoriesIsLoading);

export const useJobBudgetSelectedLines = () =>
  useContextSelector(JobContext, (context) => context.budgetSelectedLines);

export const useJobInvoiceSelectedDraws = () =>
  useContextSelector(JobContext, (context) => context.invoiceSelectedDraws);

export const useJobPermission = () => {
  const { hasPermission } = useUserInfo();

  const canManageJobs = useMemo(
    () => hasPermission(BasePermissions.MANAGE_JOBS),
    [hasPermission]
  );

  const canEditInitialBudget = useMemo(
    () => hasPermission(BasePermissions.EDIT_INITIAL_BUDGET),
    [hasPermission]
  );

  const canViewAllJobs = useMemo(
    () => hasPermission(BasePermissions.VIEW_ALL_JOBS),
    [hasPermission]
  );

  return {
    canManageJobs,
    canEditInitialBudget,
    canViewAllJobs,
  };
};
