import React, {
  type ComponentPropsWithoutRef,
  forwardRef,
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { Flex, Portal } from "@adaptive/design-system";
import {
  useDeepMemo,
  useEvent,
  useIsDragging,
} from "@adaptive/design-system/hooks";
import { asyncNoop } from "@utils/noop";
import cn from "clsx";

import { useAttachmentActions } from "../../store/attachments/hooks";
import {
  type AttachableLifeCycleHooks,
  type AttachableOptions,
} from "../../store/attachments/slice";

import { SUPPORTED_UPLOAD_FORMATS } from "./constants";
import {
  CurrentDragAction,
  type DragActionProps,
  DropZoneState,
  type Keys as DropZoneStates,
} from "./drag-states";
import type { ImageType } from "./types";
import styles from "./draggable.module.css";

export type Messages = {
  [State in DropZoneStates as `${string & Lowercase<State>}Message`]?: string;
};

type BaseDropZoneProps = Messages & {
  concurrentLimit?: number;
  children?: ReactNode | ReactNode[];
  showBorder?: boolean;
  flip?: "horizontal" | "vertical";
  hasPermission?: boolean;
  portal?: boolean;
  onError?: (e: Error) => void;
  imageFormats?: ImageType[];
} & Omit<ComponentPropsWithoutRef<typeof Flex>, "onDrop">;

type AttachableDropZoneProps = BaseDropZoneProps &
  AttachableLifeCycleHooks & {
    onDrop?: never;
    onComplete?: () => void;
    attachableOptions: AttachableOptions;
  };

type ManualDropzoneProps = BaseDropZoneProps & {
  onDrop: (files: File[]) => void | Promise<void>;
  onComplete?: never;
  onItemUpload?: never;
  onItemStarted?: never;
  onItemComplete?: never;
  attachableOptions?: never;
};

export type DropZoneProps = AttachableDropZoneProps | ManualDropzoneProps;

type DaState = Omit<DragActionProps, "onClick" | "flip">;

type DroppableAreaProps = ComponentPropsWithoutRef<typeof Portal>;

const DroppableArea = forwardRef<HTMLDivElement, DroppableAreaProps>(
  ({ className, ...props }, ref) => {
    const isDragging = useIsDragging();

    return (
      <Portal
        {...props}
        ref={ref}
        tabIndex={-1}
        className={cn(className, styles["portal"], {
          [styles["-visible"]]: isDragging,
        })}
      />
    );
  }
);

DroppableArea.displayName = "DroppableArea";

//TODO prop flag to support many, indicate expected record type
export const DropZone = ({
  attachableOptions,
  concurrentLimit,
  onComplete = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  onDrop: onDropProp,
  onItemComplete,
  onItemStarted,
  onItemUpload,
  onError,
  children,
  idleMessage,
  pendingMessage,
  draggingMessage,
  showBorder = false,
  hasPermission = true,
  flip = "vertical",
  imageFormats = ["png", "jpg"],
  className,
  portal,
  ...flexProps
}: DropZoneProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const [daState, setDaState] = useState<DaState>({
    state: DropZoneState.IDLE,
    message: idleMessage,
  });

  /**
   * Here we did this workaround to avoid redux usage if there's
   * onDrop prop passed in. This is because we don't want to
   * force the consumer to use redux if they don't want to.
   */
  const { requests, submitAttachables } =
    onDropProp !== undefined
      ? { requests: [], submitAttachables: asyncNoop }
      : useAttachmentActions(); // eslint-disable-line react-hooks/rules-of-hooks

  const uploadStatus: DropZoneStates = useMemo(() => {
    const statuses = Object.values(requests).map(({ status }) => status);

    if (
      statuses.length &&
      statuses.every(({ status }) => status !== "pending")
    ) {
      return "PENDING";
    }

    return "IDLE";
  }, [requests]);

  const enhancedOnError = useEvent((e: Error) => {
    timeoutRef.current = setTimeout(() => {
      setDaState((daState) =>
        daState.state === DropZoneState.WARNING
          ? { state: DropZoneState.IDLE, message: idleMessage }
          : daState
      );
    }, 3000);

    onError?.(e);
  });

  const supportedUploadFormats = useMemo(
    () => [
      ...SUPPORTED_UPLOAD_FORMATS,
      ...imageFormats.map((format) => `image/${format}`),
      ...(imageFormats.includes("jpg") ? ["image/jpeg"] : []),
    ],
    [imageFormats]
  );

  const onDrop = async (files: File[]) => {
    clearTimeout(timeoutRef.current);

    if (!hasPermission) return;
    if (concurrentLimit && files.length > concurrentLimit) {
      const message = `Only ${concurrentLimit} files allowed at a time`;
      setDaState({ state: DropZoneState.WARNING, message });
      enhancedOnError?.(new Error(message));
      return;
    }

    const [file] = files;
    if (!supportedUploadFormats.some((format) => file.type.includes(format))) {
      const message = "Only images and pdfs are supported";
      setDaState({ state: DropZoneState.WARNING, message });
      enhancedOnError?.(new Error(message));
      return;
    }

    const isValidSize = files.every(
      (file) => file.size / 1024 <= window.MAX_ATTACHABLE_FILE_SIZE_MB! * 1024
    );

    if (!isValidSize) {
      const message = `File size is too large. Max size is ${window.MAX_ATTACHABLE_FILE_SIZE_MB}MB`;
      setDaState({ state: DropZoneState.WARNING, message });
      enhancedOnError?.(new Error(message));
      return;
    }

    setDaState({
      state: DropZoneState.PENDING,
      message: pendingMessage,
    });

    // TODO: we should just expose a function as a prop
    // then any consumer can reuse this component and do whatever they want
    try {
      if (onDropProp !== undefined) {
        await onDropProp(files);
      } else {
        await submitAttachables(
          files,
          attachableOptions,
          onComplete,
          onItemComplete,
          onItemStarted,
          onItemUpload
        );
      }
    } catch (e) {
      enhancedOnError?.(e as Error);
      throw e;
    }

    setDaState({
      state: DropZoneState.IDLE,
      message: idleMessage,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !hasPermission,
  });

  useEffect(() => {
    setDaState((prevDaState) => ({
      state: isDragActive ? DropZoneState.DRAGGING : uploadStatus,
      message: isDragActive ? draggingMessage : prevDaState.message,
    }));
  }, [uploadStatus, isDragActive, draggingMessage, idleMessage]);

  const enhancedDaState = useDeepMemo<DaState>(() => {
    if (portal) return { state: "DRAGGING", message: draggingMessage };

    let message = daState.message;

    if (daState.state === "IDLE" && idleMessage) {
      message = idleMessage;
    }

    if (daState.state === "PENDING" && pendingMessage) {
      message = pendingMessage;
    }

    if (daState.state === "DRAGGING" && draggingMessage) {
      message = draggingMessage;
    }

    return { state: daState.state, message };
  }, [daState, idleMessage, pendingMessage, draggingMessage]);

  const { onClick, ...rootProps } = getRootProps();
  const inputProps = getInputProps();

  if (portal) {
    return (
      <>
        <DroppableArea {...rootProps}>
          <Flex
            direction={flip === "vertical" ? "column" : "row"}
            gap="xl"
            justify="center"
            align="center"
            width="full"
            height="full"
            className={styles["wrapper"]}
          >
            <input
              type="file"
              data-skip-focusable=""
              disabled={!hasPermission}
              {...inputProps}
            />
            <CurrentDragAction
              {...enhancedDaState}
              flip={flip}
              onClick={onClick}
              disabled={!hasPermission}
              imageFormats={imageFormats}
            />
          </Flex>
        </DroppableArea>
        {children}
      </>
    );
  }

  return (
    <Flex
      {...rootProps}
      className={cn(
        { [styles["active"]]: showBorder || isDragActive },
        className
      )}
      {...flexProps}
      tabIndex={-1}
      minHeight="100px"
    >
      {(!hasPermission || !isDragActive) && children ? (
        children
      ) : (
        <Flex
          direction={flip === "vertical" ? "column" : "row"}
          gap="xl"
          justify="center"
          align="center"
          width="full"
        >
          <input
            type="file"
            data-skip-focusable=""
            disabled={!hasPermission}
            {...inputProps}
          />
          <CurrentDragAction
            {...enhancedDaState}
            flip={flip}
            onClick={onClick}
            disabled={!hasPermission}
            imageFormats={imageFormats}
          />
        </Flex>
      )}
    </Flex>
  );
};
