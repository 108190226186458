import { is } from "@adaptive/design-system/utils";
import { createSelector, Selector } from "@reduxjs/toolkit";
import {
  ChangeSet,
  EditDocument,
  SavedDocument,
  VirtualDocument,
} from "@store/vendors/types";
import { camelToSpaceCase } from "@utils/schema/converters";

import type { RootState } from "../types";

import { Form, Transaction, type VendorState } from "./slice";
import { initialState } from "./utils";

type VendorKey = keyof VendorState["form"];

type VendorSelector<T extends VendorKey> = (
  s: RootState
) => VendorState["form"][T];

type SelectorMap<T extends { [key: string]: unknown }> = {
  [Field in keyof T]: Selector<RootState, T[Field]>;
};

export const vendorSelector: Selector<RootState, VendorState["form"]> = (
  state: RootState
) => state.vendors.form;

const getVendorFieldSelector: <T extends VendorKey>(
  s: T
) => VendorSelector<T> = (field) => (s) => vendorSelector(s)[field];

export const vendorSelectors = Object.keys(initialState.form).reduce(
  (selectors, key) => ({
    ...selectors,
    [key]: getVendorFieldSelector(key as VendorKey),
  }),
  {}
) as SelectorMap<VendorState["form"]>;

export const selectVendorFetchStatus = createSelector(
  (state: RootState) => state.vendors.fetchStatus,
  (fetchStatus) => fetchStatus
);

export const creationId = (state: RootState) => state.vendors.creationId;

export const byCreationId =
  (id: string): Selector<RootState, Transaction | undefined> =>
  (state: RootState) => {
    return state.vendors?.transactions[id];
  };

const recursiveGetChangedFields = <T extends Record<string, unknown>>(
  a: T,
  b: T
) => {
  const keys = Object.keys(a);
  const changedKeys: string[] = keys.filter((key) => {
    const itemA = a?.[key];
    const itemB = b?.[key];

    if (is.object(itemA) && is.object(itemB)) {
      return recursiveGetChangedFields(itemA, itemB).length > 0;
    } else if (Array.isArray(itemA) && Array.isArray(itemB)) {
      return (
        itemA.length !== itemB.length ||
        itemA.some(
          (item: unknown, i: number) =>
            recursiveGetChangedFields(item, itemB[i]).length > 0
        )
      );
    }

    return itemA !== itemB;
  });
  return changedKeys;
};

const selectInitialSnapshot =
  <T extends keyof Form["initialSnapshot"]>(key: T) =>
  (state: RootState): Form["initialSnapshot"][T] =>
    state.vendors.form.initialSnapshot[key];

const selectUnsavedInfoChanges = createSelector(
  [vendorSelectors.info, selectInitialSnapshot("info")],
  (current, initial) => {
    const changedFields = recursiveGetChangedFields(current, initial);
    return changedFields.length ? changedFields.map(camelToSpaceCase) : null;
  }
);

const selectUnsavedBankingChanges = createSelector(
  [vendorSelectors.banking, selectInitialSnapshot("banking")],
  (current, initial) => {
    const changedFields = recursiveGetChangedFields(current, initial);
    return changedFields.length ? changedFields.map(camelToSpaceCase) : null;
  }
);

export const selectUnsavedDocumentsChanges = createSelector(
  [vendorSelectors.documents, selectInitialSnapshot("documents")],
  (current, initial) => {
    const changeSet: ChangeSet<VirtualDocument, SavedDocument, EditDocument> =
      {};

    // if any of the current documents are virtual, we know there are unsaved changes
    const virtualDocs = current.filter((doc) => !(doc as SavedDocument).url);
    if (virtualDocs.length) {
      changeSet.added = virtualDocs as VirtualDocument[];
    }

    if (current.length - virtualDocs.length < initial.length) {
      changeSet.removed = (initial as SavedDocument[]).filter(
        (initialDoc) =>
          !(current as SavedDocument[]).find(
            (currentDoc) => currentDoc.id === initialDoc.id
          )
      );
    }

    return Object.keys(changeSet).length ? changeSet : null;
  }
);

export const selectVendorDocumentItems = createSelector(
  [vendorSelectors.documents],
  (documents) =>
    documents.map((doc) => ({
      ...doc,
      isVirtual: doc.document instanceof File,
    }))
);

export const selectUnsavedVendorInfoOrBankingChanges = createSelector(
  [selectUnsavedInfoChanges, selectUnsavedBankingChanges],
  (info, banking) => (info || banking ? { info, banking } : null)
);

export const selectHasEmailChange = createSelector(
  [selectUnsavedInfoChanges],
  (info) => (info?.length && info.includes("email") ? true : false)
);

export const selectIsSubmitting = createSelector(
  [vendorSelectors.isSubmitting],
  (isSubmitting) => isSubmitting
);

export const selectVendorNeverSaved = createSelector(
  [vendorSelectors.info],
  (info) => !info.url
);
