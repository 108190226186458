import React from "react";
import { type TableColumn, Text } from "@adaptive/design-system";
import type { BudgetLineItems } from "@api/jobs";

import {
  CostsActualColumn,
  CostsActualFooter,
  CostsBudgetColumn,
  CostsBudgetFooter,
  CostsBudgetHeader,
  CostsBudgetRemainingTooltip,
  CostsChangesColumn,
  CostsChangesFooter,
  CostsChangesHeader,
  CostsRemainingColumn,
  CostsRemainingFooter,
  CostsRevisedBudgetColumn,
  CostsRevisedBudgetFooter,
  CostsUnpaidColumn,
  CostsUnpaidFooter,
  EmptyColumn,
  RevenuesChangesColumn,
  RevenuesChangesFooter,
  RevenuesChangesHeader,
  RevenuesDrawnColumn,
  RevenuesDrawnFooter,
  RevenuesDrawnHeader,
  RevenuesDrawnRemainingColumn,
  RevenuesDrawnRemainingFooter,
  RevenuesDrawRemainingTooltip,
  RevenuesMarkupColumn,
  RevenuesOwnersBudgetColumn,
  RevenuesOwnersBudgetFooter,
  RevenuesOwnersBudgetHeader,
  RevenuesRevisedBudgetColumn,
  RevenuesRevisedBudgetFooter,
  TransactionsCategoryColumn,
  TransactionsCategoryFooter,
  TransactionsLineColumn,
  TransactionsLineFooter,
} from "./lines-components";

export type Line = BudgetLineItems & {
  remaining: number;
  budgetRemaining: number;
};

type LineColumn = TableColumn<Line>;

export const getIsDeletable = (row: BudgetLineItems) => {
  return (
    row.jobCostMethod.url !== row.jobCostMethod.parent &&
    !row.spent &&
    !row.hasInvoices &&
    !row.hasChanges
  );
};

/**
 * Columns definition
 */
type GetLineColumnsProps = {
  hasCategories: boolean;
  hasChanges: boolean;
  hasOwnersAmount: boolean;
  hasExternalRevisedBudget: boolean;
};

export const getLineColumns = ({
  hasChanges,
  hasCategories,
  hasOwnersAmount,
  hasExternalRevisedBudget,
}: GetLineColumnsProps) => {
  const info: LineColumn = {
    id: "info",
    columns: [
      {
        id: "name",
        name: <Text weight="bold">Line items</Text>,
        render: (row) => <TransactionsLineColumn {...row} />,
        footer: <TransactionsLineFooter />,
        sortable: "asc",
      },
    ],
  };

  if (hasCategories) {
    info.columns.push({
      id: "category",
      name: <Text weight="bold">Categories</Text>,
      render: (row) => <TransactionsCategoryColumn {...row} />,
      footer: <TransactionsCategoryFooter />,
      sortable: "asc",
    });
  }

  const costs: LineColumn = {
    id: "costs",
    name: "Costs",
    columns: [
      {
        id: "budget",
        name: <CostsBudgetHeader />,
        render: (row) => <CostsBudgetColumn {...row} />,
        footer: <CostsBudgetFooter />,
        sortable: true,
      },
    ],
    highlight: "info",
  };

  if (hasChanges) {
    costs.columns.push(
      {
        id: "changes",
        name: <CostsChangesHeader />,
        render: (row) => <CostsChangesColumn {...row} />,
        footer: <CostsChangesFooter />,
        sortable: true,
      },
      {
        id: "revisedBudget",
        name: "Revised budget",
        render: (row) => <CostsRevisedBudgetColumn {...row} />,
        footer: <CostsRevisedBudgetFooter />,
        message: "[Budget] + [Changes]",
        sortable: true,
      }
    );
  }

  costs.columns.push({
    id: "actual",
    message: "Total cost of approved bills & receipts",
    name: "Actual costs",
    render: (row) => <CostsActualColumn {...row} />,
    footer: <CostsActualFooter />,
    sortable: true,
  });

  if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) {
    costs.columns.push({
      id: "unpaid",
      name: "Unpaid bills",
      message: "Total cost of unpaid bills",
      render: (row) => <CostsUnpaidColumn {...row} />,
      footer: <CostsUnpaidFooter />,
      sortable: true,
    });
  }

  costs.columns.push({
    id: "costsRemaining",
    name: "Budget remaining",
    render: (row) => <CostsRemainingColumn {...row} />,
    footer: <CostsRemainingFooter />,
    message: <CostsBudgetRemainingTooltip />,
    sortable: true,
  });

  const revenues: LineColumn = {
    id: "revenues",
    name: "Revenue",
    columns: [],
    highlight: "success",
  };

  if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
    revenues.columns.push({
      id: "markup",
      name: "Markup",
      render: (row) => <RevenuesMarkupColumn {...row} />,
      footer: <EmptyColumn />,
    });
  }

  if (hasOwnersAmount) {
    revenues.columns.push({
      id: "ownersBudget",
      name: <RevenuesOwnersBudgetHeader />,
      render: (row) => <RevenuesOwnersBudgetColumn {...row} />,
      footer: <RevenuesOwnersBudgetFooter />,
      sortable: true,
    });

    if (hasChanges && hasExternalRevisedBudget) {
      revenues.columns.push(
        {
          id: "revenuesChanges",
          name: <RevenuesChangesHeader />,
          render: (row) => <RevenuesChangesColumn {...row} />,
          footer: <RevenuesChangesFooter />,
          sortable: true,
        },
        {
          id: "revenuesRevisedBudget",
          name: "Revised budget",
          message: "[Budget] + [Changes]",
          render: (row) => <RevenuesRevisedBudgetColumn {...row} />,
          footer: <RevenuesRevisedBudgetFooter />,
          sortable: true,
        }
      );
    }
  }

  revenues.columns.push(
    {
      id: "drawn",
      message: "Total amount on draft & synced draws",
      name: <RevenuesDrawnHeader />,
      render: (row) => <RevenuesDrawnColumn {...row} />,
      footer: <RevenuesDrawnFooter />,
      sortable: true,
    },
    {
      id: "remaining",
      name: "Draw remaining",
      message: <RevenuesDrawRemainingTooltip />,
      render: (row) => <RevenuesDrawnRemainingColumn {...row} />,
      footer: <RevenuesDrawnRemainingFooter />,
      sortable: true,
    }
  );

  return [info, costs, revenues];
};

export const LINE_COLUMN_ID_TO_PROP = {
  name: "jobCostMethod.displayName",
  category: "category.displayName",
  budget: "builderAmount",
  changes: "changeAmount",
  revisedBudget: "builderRevisedAmount",
  actual: "spent",
  unpaid: "unpaidBills",
  costsRemaining: "budgetRemaining",
  ownersBudget: "ownersAmount",
  revenuesChanges: "externalChangeAmount",
  revenuesRevisedBudget: "ownersRevisedAmount",
  drawn: "invoicedAmount",
  remaining: "remaining",
};
