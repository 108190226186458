import { toast } from "@adaptive/design-system";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import lodashExtend from "lodash.assignin";
import lodashIsEmpty from "lodash.isempty";
import { BILL_STATUS, LIEN_WAIVER_STATUS } from "../../bills/constants";
import { createApproval, destroyApproval } from "../api/approvals";
import {
  archiveBill as archiveBillAPI,
  getBill,
  getConvertedBill,
  updateBill,
  deleteBill,
  convert,
  convertToPurchaseOrder as convertToPurchaseOrderAPI,
} from "../api/bills";
import { api } from "../utils/api";
import { incompleteLines } from "../utils/usefulFunctions";
import {
  addAllBill,
  addDraftBill,
  fetchAllBills,
  fetchDraftBills,
  removeAllBill,
  removeDraftBill,
  updateAllBill,
  updateDraftBill,
} from "./billListSlice";
import { SUPPORTED_UPLOAD_FORMATS as GENERIC_SUPPORTED_UPLOAD_FORMATS } from "../components/draggable";
import {
  selectClientSettings,
  selectRealm,
  selectRealmId,
} from "./user/selectors-raw";
import { handleErrors } from "../api/handle-errors";
import { billSelector } from "@src/bills/utils";
import { formatDate, isEqual, dotObject } from "@adaptive/design-system/utils";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";
import { PollError, poll } from "../utils/poll";
import { navigate } from "../utils/custom-events";

const TODAY = formatDate(new Date(), "yyyy-MM-dd");

const DEFAULT_BILL = {
  url: null,
  lines_count: 0,
  amount_to_pay: undefined,
  duplicate: [],
  bill_payments: [],
  customers: [],
  default_account_balance: { url: null },
  vendor: { url: null, display_name: null, email: null },
  attachables: [],
  doc_number: null,
  date: TODAY,
  due_date: null,
  total_amount: null,
  realm: null,
  comments: [],
  created_at: null,
  is_creator: true,
  archived: false,
  errors: [],
  related_errors: [],
  tax: { isSet: false, value: null },
  lines: [],
  balance: null,
  approval_workflows: [],
  approvals: [],
  review_status: BILL_STATUS.DRAFT,
  published_to_quickbooks: false,
  email_body_attachable: {},
  initial_review_status: BILL_STATUS.DRAFT,
  initial_vendor: { display_name: null, url: null },
  can_be_edited: false,
  is_vendor_credit: false,
  is_approval_evaluated: false,
  is_approved: false,
  can_approve: false,
  linked_invoices: [],
  default_lien_waiver_template: null,
  default_lien_waiver_status: null,
  lien_waivers: [],
};

const initialState = {
  bill: DEFAULT_BILL,
  staticBill: DEFAULT_BILL,
  enterBillManually: false,
  billFormEdited: false,
  billStatus: "loaded",
  source: null,
  prediction: { canPredict: false, predictions: null },
  showCreateNewDialog: false,
};

const billSlice = createSlice({
  name: "bill",
  initialState,
  reducers: {
    reset: (state, action) => {
      if (state.billStatus === "loading") {
        state.source.cancel("Bill closed before fetch was complete");
      }

      state = JSON.parse(JSON.stringify(initialState));

      if (action.payload) {
        state.bill.realm = action.payload;
      }

      return state;
    },
    recordBillUpdate: (state, action) => {
      state.bill = lodashExtend(state.bill, action.payload);
    },
    updateBillPayment: (state, action) => {
      state.bill.bill_payments = state.bill.bill_payments.map((billPayment) => {
        if (billPayment.id === action.payload.id) {
          return { ...billPayment, ...action.payload };
        }
        return billPayment;
      });
    },
    updateStaticLinesLinkedStatus: (state, action) => {
      state.staticBill.lines = action.payload;
    },
    setShowCreateNewDialog: (state, action) => {
      state.showCreateNewDialog = action.payload;
    },
    updateBillApprovalWorkflow: (state, action) => {
      state.bill = bill.approvalWorkflows.map((approvalWorkflow) => {
        if (approvalWorkflow.id === action.payload.id) {
          return action.payload;
        }
        return approvalWorkflow;
      });
    },
    setBillStatus: (state, action) => {
      state.billStatus = action.payload;
    },
    setBillSource: (state, action) => {
      state.source = action.payload;
    },
    updateLienWaiver: (state, action) => {
      state.bill.lien_waivers = state.bill.lien_waivers.map((lienWaiver) => {
        if (lienWaiver.id === action.payload.id) {
          return { ...lienWaiver, ...action.payload };
        }
        return lienWaiver;
      });
    },
    setEnterBillManually: (state, action) => {
      state.enterBillManually = action.payload;
    },
    recordLineUpdate: (state, action) => {
      const { id, payload } = action.payload;

      let index = state.bill.lines.findIndex(
        (line) => line.id == id && !line.deleted
      );

      if (index === -1) {
        index = state.bill.lines.findIndex(
          (line) => line.linked_transaction?.id == id && !line.deleted
        );
      }

      const costCodeAccountHasChanged = payload?.item
        ? state.bill.lines[index].item?.url !== payload?.item?.url ||
          state.bill.lines[index].account?.url !== payload?.account?.url
        : false;

      const customerHasChanged = payload?.customer
        ? state.bill.lines[index].customer?.url !== payload?.customer?.url
        : false;

      const amountHasChanged = payload?.amount
        ? state.bill.lines[index].amount != payload?.amount
        : false;

      const blockRemainingBudget =
        costCodeAccountHasChanged || customerHasChanged || amountHasChanged;

      if (blockRemainingBudget) {
        state.bill.lines[index].remaining_budget_after_this_bill_blocked = true;
      }

      state.bill.lines[index] = lodashExtend(state.bill.lines[index], payload);
    },
    updateTotalFromLines: (state) => {
      const subTotal = getTotalFromLines(state.bill.lines);
      state.bill.total_amount = state.bill.tax?.isSet
        ? sum(state.bill.tax.value, subTotal)
        : subTotal;
    },
    addAttachable: (state, action) => {
      state.bill.attachables.push(action.payload);
    },
    unlinkPOLine: (state, action) => {
      const index = state.bill.lines.findIndex(
        (line) =>
          !line.deleted &&
          (line.id == action.payload ||
            line.linked_transaction?.id == action.payload)
      );
      state.bill.lines[index].linked_transaction = null;
    },
    unlinkPOLines: (state) => {
      state.bill.lines = state.bill.lines.map((line) => ({
        ...line,
        linked_transaction: null,
      }));
    },
    delinkLines: (state, action) => {
      state.bill.lines = state.bill.lines.map((line) => {
        if (action.payload.some((li) => li.id == line.linked_transaction?.id)) {
          line.linked_transaction = null;
          line.deleted = true;
        }
        return line;
      });
    },
    removeAttachable: (state) => {
      state.bill.attachables.shift();
    },
    createBill: (state, action) => {
      const payload = { ...action.payload };

      state.bill = {
        ...payload,
        initial_vendor: payload.vendor,
        initial_review_status: payload.review_status,
        tax:
          Number(payload.sales_tax) !== 0
            ? { isSet: true, value: Number(payload.sales_tax) }
            : { isSet: false, value: 0 },
      };

      if (state.bill.lines.length === 0) {
        state.bill.lines = [mapLine(state)];
      }

      if (payload.id) state.staticBill = state.bill;
    },
    addLine: (state, action) => {
      state.bill.lines = state.bill.lines.concat(
        mapLine(state, action.payload)
      );
    },
    updateLines: (state, action) => {
      state.bill.lines = action.payload;
    },
    addLines: (state, action) => {
      const newLines = action.payload.map((line) => mapLine(state, line));

      state.bill.lines = state.bill.lines
        .filter((line) => {
          const hasId = !!line.id;
          const isEmpty =
            !line.amount &&
            !line.item?.url &&
            !line.customer?.url &&
            !line.description;

          return hasId && !isEmpty;
        })
        .concat(newLines);

      state.bill.total_amount = sum(
        state.bill.total_amount,
        getTotalFromLines(newLines)
      );
    },
    removeLine: (state, action) => {
      const index = state.bill.lines.findIndex(
        (line) => line.id == action.payload
      );
      state.bill.lines[index].deleted = true;
    },
    splitLine: (state, action) => {
      const { newLines, oldLine } = action.payload;
      const oldLineIndex = state.bill.lines.findIndex(
        (line) => line.id == oldLine.id
      );
      state.bill.lines.splice(oldLineIndex + 1, 0, ...newLines);
      state.bill.lines[oldLineIndex].deleted = true;
    },
    updatePredictions: (state, action) => {
      state.prediction.canPredict = action.payload.canPredict;
      state.prediction.predictions = { ...action.payload.predictions };
    },
    setBillFormStatus: (state, action) => {
      state.billFormEdited = action.payload;
    },
    setBillDefaultLienWaiver: (state, action) => {
      if (!action.payload) {
        state.bill.default_lien_waiver_template = null;
        state.bill.default_lien_waiver_status = null;
      } else if (action.payload === "not_required") {
        state.bill.default_lien_waiver_template = null;
        state.bill.default_lien_waiver_status = action.payload;
      } else {
        state.bill.default_lien_waiver_template = action.payload;
        state.bill.default_lien_waiver_status = null;
      }
    },
  },
});

export const {
  createBill,
  updateLines,
  recordBillUpdate,
  recordLineUpdate,
  updateBillPayment,
  updateTotalFromLines,
  setBillStatus,
  setBillSource,
  setEnterBillManually,
  removeLine,
  splitLine,
  reset,
  delinkLines,
  unlinkPOLine,
  addAttachable,
  unlinkPOLines,
  removeAttachable,
  updatePredictions,
  setShowCreateNewDialog,
  updateBillApprovalWorkflow,
  setBillFormStatus,
  updateStaticLinesLinkedStatus,
  setBillDefaultLienWaiver,
  updateLienWaiver,
} = billSlice.actions;

const getTotalFromLines = (lines) =>
  sumBy(
    lines.filter((line) => !line.deleted),
    "amount"
  );

/**
 * @todo replace it with abortController
 */
const handleAxiosCancelException = (e) => {
  if (!axios.isCancel(e)) {
    throw e;
  } else {
    return undefined;
  }
};

const mapLine = (state, payload) => {
  const baseId = `added_line_`;
  const suffixId = payload?.index ?? state.bill.lines.length;
  let id = `${baseId}${suffixId}`;
  id = state.bill.lines.some((line) => line.id == id)
    ? `${id}${state.bill.lines.length}`
    : id;
  return {
    id,
    type: payload?.type,
    customer: payload?.customer ?? { display_name: null, url: null },
    item: payload?.item ?? { display_name: null, url: null },
    linked_transaction: payload?.linked_transaction ?? null,
    account: payload?.account ?? { display_name: null, url: null },
    amount: payload?.total ? payload?.total : 0,
    description: payload?.description ?? "",
    created: true,
    realm: state.bill.realm,
    linked_to_draft_invoice: false,
    remaining_budget_after_this_bill_blocked: true,
    billable_status: payload?.billable_status ?? "NotBillable",
    split: "0",
    is_a_variance: false,
  };
};

export const addLine =
  (payload = {}) =>
  async (dispatch, getState) => {
    const { is_billable_default_bill } = selectClientSettings(getState());

    dispatch(
      billSlice.actions.addLine({
        billable_status: is_billable_default_bill ? "Billable" : "NotBillable",
        ...payload,
      })
    );
  };

export const checkBillFormStatus = () => async (dispatch, getState) => {
  const { bill } = getState().bill;
  const originalBill = { ...initialState.bill, realm: bill.realm };
  originalBill.lines = [mapLine({ bill: originalBill })];
  const currentBill = { ...bill, attachables: [] };
  if (!isEqual(originalBill, currentBill)) dispatch(setBillFormStatus(true));
  else dispatch(addPredictions(true));
};

export const resetBill = () => (dispatch, getState) => {
  const realm = selectRealm(getState());
  dispatch(reset(realm));
};

export const removeBill = () => async (dispatch, getState) => {
  const { bill } = getState().bill;
  try {
    await deleteBill(bill.id);
  } catch (e) {
    if (!handleErrors(e)) throw e;
  }
};

export const archiveBill =
  (unlinkInvoiceLineOption) => async (dispatch, getState) => {
    const { bill } = getState().bill;
    try {
      await archiveBillAPI(bill.id, unlinkInvoiceLineOption);
    } catch (e) {
      if (!handleErrors(e)) throw e;
    }
  };

export const convertToExpense = () => async (dispatch, getState) => {
  const { bill } = getState().bill;
  try {
    const expense = await convert(bill.id);
    return expense;
  } catch (e) {
    if (!handleErrors(e)) throw e;
  }
};

export const convertToPurchaseOrder = () => async (dispatch, getState) => {
  const { bill } = getState().bill;
  try {
    const purchaseOrder = await convertToPurchaseOrderAPI(bill.id);
    return purchaseOrder;
  } catch (e) {
    if (!handleErrors(e)) throw e;
  }
};

export const unarchiveCurrentBill = () => async (dispatch, getState) => {
  const { bill } = getState().bill;
  const realmId = selectRealmId(getState());

  try {
    await updateBill(bill.id, {
      archived: false,
      review_status: BILL_STATUS.DRAFT,
    });
  } catch (e) {
    if (!handleErrors(e)) throw e;
  }
  dispatch(loadBill(bill.id));
  dispatch(
    recordBillUpdate({ archived: false, review_status: BILL_STATUS.DRAFT })
  );

  const filters = [{ dataIndex: "realm", value: realmId }];

  dispatch(fetchDraftBills({ filters }));
  dispatch(fetchAllBills({ filters }));
};

export const updateBillWorkflows = (id) => async (dispatch, getState) => {
  const bill = billSelector(getState());

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  dispatch(setBillSource(source));

  try {
    const data = await getBill(id, { signal: source.token }).catch(
      handleAxiosCancelException
    );

    if (!data) return;

    if (bill.initial_review_status !== data.review_status) {
      dispatch(createBill(data));
    } else {
      dispatch(
        recordBillUpdate({
          approval_workflows: data.approval_workflows,
          approvals: data.approvals,
        })
      );
    }
  } catch (e) {
    dispatch(setBillStatus("failed"));
  }
};

export const loadBill =
  (id, { skipLoading, fieldsToUpdate } = {}) =>
  async (dispatch, getState) => {
    const bill = billSelector(getState());

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const shouldReset = bill.id != id;

    if (shouldReset) dispatch(resetBill());

    if (!skipLoading) dispatch(setBillStatus("loading"));

    dispatch(setBillSource(source));

    try {
      const data = await getBill(id, { signal: source.token }).catch(
        handleAxiosCancelException
      );

      if (!data) return;

      if (shouldReset || !fieldsToUpdate) {
        dispatch(createBill(data));
      } else if (Array.isArray(fieldsToUpdate)) {
        fieldsToUpdate.forEach((field) => {
          dispatch(recordBillUpdate({ [field]: data[field] }));
        });
      } else if (typeof fieldsToUpdate === "function") {
        fieldsToUpdate(data);
      }
      dispatch(setBillStatus("loaded"));
    } catch (e) {
      dispatch(setBillStatus("failed"));
    }
  };

export const refetchCurrentBill = (fieldsToUpdate) => (dispatch, getState) => {
  const bill = billSelector(getState());

  if (bill.id) dispatch(loadBill(bill.id, { fieldsToUpdate }));
};

export const syncInvoiceChanges = (id) => (dispatch, getState) => {
  dispatch(
    loadBill(id, {
      skipLoading: true,
      fieldsToUpdate: (data) => {
        const { staticBill, bill } = getState().bill;

        const getFieldValue = (
          dynamicLine,
          newLine,
          field,
          isObject = false
        ) => {
          const dynamicValue = dotObject.get(dynamicLine, field);
          const enhancedDynamicValue = isObject
            ? dotObject.get(dynamicValue, "url")
            : dotObject.get(dynamicLine, field);

          const staticLine = staticBill.lines.find(
            (line) => dynamicLine.id == line.id
          );

          if (staticLine) {
            const staticValue = dotObject.get(staticLine, field);
            const enhancedStaticValue = isObject
              ? dotObject.get(staticValue, "url")
              : dotObject.get(staticLine, field);

            const newValue = dotObject.get(newLine, field);
            const enhancedNewValue = isObject
              ? dotObject.get(newValue, "url")
              : dotObject.get(newLine, field);

            const isDirty = enhancedDynamicValue !== enhancedStaticValue;
            const isNewValue = enhancedDynamicValue !== enhancedNewValue;

            if (!isDirty && isNewValue) {
              return newValue;
            }
          }
          return dynamicValue;
        };

        dispatch(
          recordBillUpdate({
            vendor: data.vendor,
            linked_invoices: data.linked_invoices,
            lines: bill.lines.map((currentLine) => {
              const newLine = data.lines.find(
                (line) => currentLine.id == line.id
              );
              if (!newLine) return currentLine;
              return {
                ...currentLine,
                linked_to_draft_invoice:
                  newLine.linked_to_draft_invoice || false,
                description: getFieldValue(currentLine, newLine, "description"),
                item: getFieldValue(currentLine, newLine, "item", true),
                account: getFieldValue(currentLine, newLine, "account", true),
                billable_status: getFieldValue(
                  currentLine,
                  newLine,
                  "billable_status"
                ),
              };
            }),
          })
        );

        // Updating the linked_to_draft_invoice status for the static bill lines
        dispatch(
          updateStaticLinesLinkedStatus(
            staticBill.lines.map((staticLine) => {
              const newLine = data.lines.find(
                (line) => staticLine.id == line.id
              );

              return {
                ...staticLine,
                linked_to_draft_invoice:
                  newLine?.linked_to_draft_invoice || false,
              };
            })
          )
        );
      },
    })
  );
};

export const syncBill = (id, isForm, source) => async (dispatch) => {
  const { run, stop } = poll({
    fn: () => getBill(id, { signal: source.token }),
    validate: (data) =>
      ["done", "timeout", "failed", "discarded"].includes(
        data.file_sync_status
      ),
  });

  const previousSourceCancel = source.cancel;
  source.cancel = () => {
    previousSourceCancel();
    stop();
  };

  try {
    const data = await run();

    dispatch(updateAllBill(data));
    dispatch(updateDraftBill(data));

    if (isForm) {
      dispatch(createBill(data));
      dispatch(setBillStatus("loaded"));
    }
  } catch (e) {
    if (axios.isCancel(e)) return;

    if (e instanceof PollError) {
      toast.error("Failed to sync due timeout");
      return;
    }

    if (e.response?.status === 404) {
      const message = "Document was automatically converted to a receipt.";
      dispatch(removeDraftBill({ id }));
      if (isForm) {
        const converted = await getConvertedBill(id, {
          signal: source.token,
        });
        if (converted) {
          toast.success(message);
          return navigate(converted.converted_to);
        }
      } else {
        toast.info(`${message} Please refresh the page.`);
      }
    } else {
      throw e;
    }
  }
};

const SUPPORTED_UPLOAD_FORMATS = [
  ...GENERIC_SUPPORTED_UPLOAD_FORMATS,
  "image/heic",
  "image/png",
  "image/jpg",
  "image/jpeg",
];

export const uploadAttachable = (options, actions) => (dispatch, getState) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const { file, index } = options;
  const realm = selectRealm(getState());
  const { bill, enterBillManually } = getState().bill;

  if (!SUPPORTED_UPLOAD_FORMATS.some((format) => file.type.includes(format))) {
    const error = new Error(
      "Only images, pdfs, spreadsheets and word documents are supported"
    );
    handleErrors(error);
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  const formData = new FormData();
  const processingId = `processing_${index}`;
  const config = { headers: { "content-type": "multipart/form-data" } };
  const isForm = actions?.isForm || false;
  const saveBill =
    (actions?.onUploadSave?.includes("parent") &&
      !bill.url &&
      !enterBillManually) ||
    !isForm ||
    false;

  if (saveBill) {
    dispatch(
      addAllBill({
        ...initialState.bill,
        file_sync_status: "requested",
        id: processingId,
      })
    );
    dispatch(
      addDraftBill({
        ...initialState.bill,
        file_sync_status: "requested",
        id: processingId,
      })
    );
  }

  formData.append("document", file);
  if (realm) formData.append("realm", realm.url);
  if (bill.url && isForm) formData.append("parent", bill.url);

  return api
    .post(`/api/attachables/?save_bill=${saveBill}`, formData, config)
    .then((response) => {
      toast.success(`${file.name} uploaded`);
      if (saveBill) {
        const { bill } = response.data;
        dispatch(removeAllBill({ id: processingId }));
        dispatch(removeDraftBill({ id: processingId }));
        dispatch(addAllBill(bill));
        dispatch(addDraftBill(bill));
        dispatch(syncBill(bill.id, isForm, source));
        if (isForm) {
          dispatch(setBillStatus("loading"));
          dispatch(setBillSource(source));
        }
      } else {
        const { predictions } = response.data;
        if (!lodashIsEmpty(predictions)) {
          dispatch(
            updatePredictions({ canPredict: !enterBillManually, predictions })
          );
        }
      }
      return dispatch(addAttachable(response.data));
    })
    .catch((error) => {
      handleErrors(error);
      throw error;
    });
};

export const addLinkedLines =
  ({ lines, purchaseOrder }) =>
  (dispatch, getState) => {
    const { bill } = getState().bill;
    const { is_billable_default_bill } = selectClientSettings(getState());

    dispatch(
      billSlice.actions.addLines(
        lines
          // Filtering lines to not duplicated them if the already exist
          .filter(
            (line) =>
              !bill.lines.some(
                (l) => l.linked_transaction?.id == line.id && !l.deleted
              )
          )
          .map((line, index) => ({
            is_spent: false,
            billable_status: is_billable_default_bill
              ? "Billable"
              : "NotBillable",
            ...line,
            total: line.balance,
            linked_transaction: {
              id: line.id,
              url: line.url,
              parent: {
                id: purchaseOrder.id,
                url: purchaseOrder.url,
                type: purchaseOrder.type,
                doc_number: purchaseOrder.doc_number,
              },
              spent: purchaseOrder.spent,
              amount: line.amount || line.total_amount,
              balance: line.balance || line.open_balance,
              object_id: purchaseOrder.id,
            },
            index: `${line.id}_${index}`,
          }))
      )
    );
  };

export const addPredictions = (canPredict) => (dispatch, getState) => {
  const { prediction, bill } = getState().bill;
  if (canPredict) {
    // Remove all the old lines before adding the new predictions.
    bill.lines.map((line) => dispatch(removeLine(line.id)));

    // Update the bill with the data from the predictions.
    // Don't include the line data as that will be added in the next step.
    let {
      lines = [],
      sales_tax,
      ...predictionsWithoutLines
    } = prediction.predictions;
    if (sales_tax !== undefined) {
      // if there is a sales_tax it should be added
      predictionsWithoutLines = {
        ...predictionsWithoutLines,
        tax: { isSet: true, value: sales_tax },
      };
    }
    dispatch(recordBillUpdate(predictionsWithoutLines));

    lines.map((line) => {
      // Convert 'amount' to 'total' as that is what the addLine reducer expects.
      dispatch(
        addLine({
          ...line,
          total: line.amount,
          type: "ItemBasedExpenseLineDetail",
        })
      );
    });
  }

  // Recalculate the bill total after adding all the lines.
  dispatch(updateTotalFromLines());
  dispatch(updatePredictions({ canPredict: false, predictions: null }));
};

export const deleteAttachment = (attachables) => (dispatch) => {
  attachables.forEach((attachable) => {
    api
      .delete(`/api/attachables/${attachable.id}/`)
      .then(() => {
        dispatch(removeAttachable());
        return null;
      })
      .catch((error) => {
        throw error;
      });
  });
};

export const discardBill = () => (dispatch, getState) => {
  const { bill } = getState().bill;
  if (bill?.id) {
    deleteBill(bill.id).then(() => {
      dispatch(fetchDraftBills());
      dispatch(fetchAllBills());
      dispatch(resetBill());
    });
  }
};

// TODO(BOB-2166): Delete this when removing old approvals logic.
export const changeCurrentBillStatus =
  (status) => async (dispatch, getState) => {
    const user = getState().user.user;
    const bill = getState().bill.bill;
    dispatch(
      recordBillUpdate({
        review_status: status,
        approvals: bill.approvals.map((approval) =>
          approval.approved_by.id === user.id
            ? { ...approval, status: "PENDING" }
            : approval
        ),
      })
    );
  };

export const putBill =
  ({ status, syncInvoiceLines, unlinkInvoiceLinesOption }) =>
  async (dispatch, getState) => {
    const { lines, ...bill } = getState().bill.bill;
    const { reset_approval_on_edit } = selectClientSettings(getState());
    const enhancedStatus = status ?? bill.review_status;

    const billData = {
      total_amount: bill.total_amount,
      date: bill.date,
      due_date: bill.due_date,
      doc_number: bill.doc_number,
      vendor: bill.vendor?.url || null,
      id: bill.id,
      url: bill.url,
      realm: bill.realm?.url,
      sales_tax: bill.tax.isSet && bill.tax.isSet ? bill.tax.value : 0,
      private_note: bill.private_note,
      amount_to_pay: bill.amount_to_pay,
      is_vendor_credit: bill.is_vendor_credit,
      sync_invoice_lines: syncInvoiceLines,
      unlink_invoice_lines_option: unlinkInvoiceLinesOption,
      default_lien_waiver_template: bill.default_lien_waiver_template,
      default_lien_waiver_status: bill.default_lien_waiver_status,
      lien_waivers: bill.lien_waivers.reduce((acc, lienWaiver) => {
        if (lienWaiver.bill_payment) {
          return acc.concat({
            id: lienWaiver.id,
            status:
              lienWaiver.status ||
              LIEN_WAIVER_STATUS.NOT_SELECTED.toLowerCase(),
            lien_waiver_template: lienWaiver.lien_waiver_template,
          });
        }
      }, []),
    };

    const billComments = bill.comments;

    if (enhancedStatus !== BILL_STATUS.DRAFT) {
      if (!bill.vendor?.url) {
        return new Promise((resolve, reject) =>
          reject(new Error(`Bills must have a vendor`))
        );
      }

      if (!bill.date) {
        return new Promise((resolve, reject) =>
          reject(new Error(`Bills must have a date`))
        );
      }

      if (lines.filter((line) => !line.deleted).length < 1) {
        return new Promise((resolve, reject) =>
          reject(new Error(`Bills must have at least one line item`))
        );
      }

      if (incompleteLines(lines, enhancedStatus).length > 0) {
        return new Promise((resolve, reject) =>
          reject(new Error(`Incomplete line items`))
        );
      }
    }

    if (bill.id) {
      const data = await getBill(bill.id).catch(handleAxiosCancelException);

      if (!data) return;

      if (data.approvals.length && reset_approval_on_edit) {
        await Promise.all(data.approvals.map(({ id }) => destroyApproval(id)));
      }
    }

    if (reset_approval_on_edit) {
      billData.approvals = await Promise.all(
        bill.approvals.map(({ approved_by }) =>
          createApproval({
            status: "PENDING",
            approved_by: approved_by.url,
          }).then(({ url }) => url)
        )
      );
    }

    // New bill lines or updated bill lines
    const billLines = lines
      .filter(
        (line) => !line.deleted // if line was deleted.
      )
      .map((line, index) => {
        const lineUpdateData = {
          customer: line.customer ? line.customer.url : null,
          item: line.item ? line.item.url : null,
          account: line.account ? line.account.url : null,
          amount: line.amount,
          description: line.description,
          billable_status: line.billable_status,
          linked_transaction: line.linked_transaction?.url ?? null,
          type: line.type,
          is_a_variance: line.is_a_variance,
          vendor: bill.vendor?.url,
          realm: bill.realm.url,
          order: index,
          id: line.created ? null : line.id,
        };

        if (!bill.id && line.created) {
          const lineCreateData = {
            ...lineUpdateData,
            parent: bill.url,
          };
          return lineCreateData;
        }
        return lineUpdateData;
      });

    let billCreatePromise = new Promise((resolve) => resolve(bill));
    if (!bill.id) {
      billCreatePromise = api
        .post(`/api/bills/`, {
          ...billData,
          review_status: enhancedStatus,
          lines: billLines,
        })
        .then((response) => {
          if (response.status >= 400) {
            throw { response };
          }

          dispatch(
            recordBillUpdate({
              id: response.data.id,
              url: response.data.url,
              created: true,
              doc_number: response.data.doc_number,
            })
          );

          billData.id = response.data.id;
          billData.url = response.data.url;
          billData.created = true;
          billData.attachables = bill.attachables;

          return billData;
        });
    }

    return billCreatePromise.then(async (bill) => {
      let requests = bill.attachables.map((attachable) =>
        api.put(`/api/attachables/${attachable.id}/`, { parent: bill.url })
      );

      // comments
      if (bill.created) {
        requests = requests.concat(
          billComments.map((comment) =>
            api.post("/api/comments/", {
              text: comment.text,
              author: comment.author.url,
              parent: bill.url,
            })
          )
        );
      }

      // If this is not a new bill being created, update it now.
      if (!bill.created) {
        requests = requests.concat([
          api
            .put(`/api/bills/${bill.id}/`, {
              ...billData,
              lines: billLines,
              ...(enhancedStatus !== BILL_STATUS.FOR_PAYMENT
                ? { review_status: enhancedStatus }
                : {}),
            })
            .then((response) => {
              if (response.status >= 400) {
                throw { response };
              }
              return response;
            }),
        ]);
      }

      return axios.all(requests).then(() => bill);
    });
  };

export const selectBill = (state) => state.bill.bill;

export const { reducer } = billSlice;
