import { arraySchema, idSchema } from "@utils/schema";
import { z } from "zod";

export const lienWaiverTemplateSchema = z.object({
  id: idSchema,
  type: z.string(),
  contentHtml: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  isDefault: z.boolean(),
  signatureFields: arraySchema(z.string()),
  updatedAt: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  url: z
    .string()
    .url()
    .nullish()
    .transform((value) => value || ""),
});

export const lienWaiverTemplateFormSchema = z.object({
  id: idSchema.nullish(),
  type: z.string().min(1, "Required"),
  contentHtml: z.string().min(1, "Required"),
  isDefault: z.boolean(),
  signatureFields: z.record(z.boolean()),
  url: z.string().url().nullish(),
});

export const dynamicVariablesListSchema = z.object({
  id: idSchema,
  type: z.string(),
  label: z.string(),
  mockValue: z.string(),
  order: z.number(),
  url: z.string().url(),
});

export const signatureFieldsSchema = z.object({
  id: idSchema,
  type: z.string(),
  isDefault: z.boolean(),
  isEditable: z.boolean(),
  url: z.string().url(),
});

export const lienWaiverTypeSchema = z.object({
  id: idSchema,
  name: z.string(),
  url: z.string().url(),
});

export const getLienWaiverTypesResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(lienWaiverTypeSchema),
});

export const getSignatureFieldsResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(signatureFieldsSchema),
});

export const getDynamicVariablesListResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(dynamicVariablesListSchema),
});

export const getLienWaiverTemplatesPayloadSchema = z
  .object({
    withStatus: z.boolean().optional(),
  })
  .or(z.void());

export const getLienWaiverTemplatesResponseSchema = arraySchema(
  lienWaiverTemplateSchema
);

export const saveLienWaiverTemplatePayloadSchema = lienWaiverTemplateFormSchema
  .omit({ signatureFields: true })
  .merge(lienWaiverTemplateSchema.pick({ signatureFields: true }));

export const saveLienWaiverTemplateResponseSchema = z.any().nullish();

export const deleteLienWaiverTemplatePayloadSchema = z.object({
  id: idSchema,
});

export const deleteLienWaiverTemplateResponseSchema = z.any().nullish();

export const getLienWaiverTemplatePayloadSchema = z.object({
  id: idSchema,
});

export const getLienWaiverTemplateResponseSchema = lienWaiverTemplateSchema;

export const renderLienWaiverPdfPayloadSchema = z.object({
  htmlContent: z.string(),
  signatureFields: arraySchema(z.string()),
});

export const renderBillLienWaiverPdfPayloadSchema = z.object({
  billId: idSchema,
  lienWaiverTemplateId: idSchema,
  paymentAmount: z.number().nullish(),
});

export const renderLienWaiverPDFResponseSchema = z.object({
  pdf: z.string(),
});

export const requestLienWaiverFormSchema = z.object({
  vendorEmail: z.string().email().min(1, "Required"),
  message: z.string(),
  files: z.array(z.any()),
});

export const requestLienWaiverPayloadSchema = z.object({
  vendorEmail: z.string().email().nullish(),
  vendorId: idSchema,
  files: z.array(z.any()).nullish(),
  status: z.string().optional(),
  billId: idSchema,
  billPaymentId: idSchema.optional(),
  message: z.string(),
  lienWaiverTemplateId: idSchema,
  paymentAmount: z.number().nullish(),
  signedAt: z.string().nullish(),
  vendor: z.string().nullish(),
});

export const updateLienWaiverRequestPayloadSchema = z.object({
  id: idSchema,
  status: z.string(),
  pdf: z.any().nullish(),
  paymentAmount: z.number().nullish(),
  lienWaiverTemplateId: idSchema.nullish(),
});

export const getExternalLienWaiverRequestPayloadSchema = z.object({
  id: idSchema,
});

export const getExternalLienWaiverRequestResponseSchema = z.object({
  id: idSchema,
  vendor: z.string(),
  fileExport: z.object({
    id: idSchema,
    document: z.string(),
  }),
  bill: z.object({
    id: idSchema,
    docNumber: z.string(),
    job: z.string(),
    url: z.string(),
    token: z.string(),
  }),
  signatureFields: z.array(z.string()),
  client: z.string(),
  paymentAmount: z.string(),
  comment: z.array(z.any()),
  signedAt: z.string().nullish(),
  pdf: z.string().nullish(),
});

export const signLienWavierRequestPayloadSchema = z.object({
  id: idSchema,
  signature: z.any().nullish(),
  printedName: z.string().nullish(),
  title: z.string().nullish(),
});
