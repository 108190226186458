import React, { useCallback, useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Flex,
  Link,
  Table,
  type TableColumn,
  TableHeaderAddon,
  type TablePaginationAddon,
  type TableRowAddon,
  type TableSelectAddon,
  TagGroup,
  Text,
  VisuallyHidden,
} from "@adaptive/design-system";
import { usePagination } from "@adaptive/design-system/hooks";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import { type InvoicesInvoice, useGetInvoicesQuery } from "@api/invoices";
import { QuickBooksSyncedIcon } from "@components/quickbooks-icon";
import {
  CURRENCY_FORMAT,
  INVOICE_STRINGS,
  useJobActions,
  useJobInvoiceIsLoading,
  useJobInvoiceSelectedDraws,
  useJobPermissions,
} from "@src/jobs";
import { InvoiceTransactionsDialog } from "@src/jobs/invoice";
import { useJobInfo } from "@store/jobs";
import * as analytics from "@utils/analytics";
import { scrollMainTop } from "@utils/scroll-main-top";

const EMPTY_INVOICES: InvoicesInvoice[] = [];

const getStatusColors = (invoice: InvoicesInvoice) => {
  switch (invoice.reviewStatus) {
    case "For Approval":
      return "warning";
    case "Approved":
      if (invoice.publishedToQuickbooks) {
        return "success";
      }
      if (invoice.errors?.length) {
        return "error";
      }
      return "warning";
    case "Paid":
      return "success";
    default:
      return "neutral";
  }
};

const getStatusTags = (invoice: InvoicesInvoice) => {
  switch (invoice.reviewStatus) {
    case "For Approval":
      return ["Not synced"];
    case "Approved":
      if (invoice.publishedToQuickbooks) {
        return ["Synced"];
      }
      if (invoice.errors?.length) {
        return ["Sync error"];
      }
      return ["Pending sync"];
    case "Paid":
      return ["Synced", "Paid"];
    default:
      return ["Not synced"];
  }
};

export const List = () => {
  const { job } = useJobInfo();

  const { canManage } = useJobPermissions();

  const invoiceIsLoading = useJobInvoiceIsLoading();

  const invoiceSelectedDraws = useJobInvoiceSelectedDraws();

  const { invoiceCreate, setInvoiceSelectedDraws } = useJobActions();

  const { setPage, page, perPage, offset, setPerPage } = usePagination({
    localStorageKey: "invoices-table-limit",
  });

  const { data = { results: EMPTY_INVOICES, count: 0 }, isLoading } =
    useGetInvoicesQuery({
      limit: perPage,
      offset,
      customerId: job.id,
    });

  const [invoice, setInvoice] = useState<InvoicesInvoice>();

  const hasDraws = data.results.length > 0;

  const row = useMemo<TableRowAddon<InvoicesInvoice>>(
    () => ({
      render: ({ row, props }) => (
        <ReactRouterLink
          {...props}
          to={`/jobs/${job.id}/invoices/${row.id}`}
          state={{ prev: window.location.pathname + window.location.search }}
        >
          <VisuallyHidden>Open #{row.docNumber}</VisuallyHidden>
        </ReactRouterLink>
      ),
      isError: (row) =>
        (row.errors ?? []).length > 0 &&
        row.errors?.some((error) => !error.isIgnored),
    }),
    [job.id]
  );

  const selectIsDisabled = useCallback((row: InvoicesInvoice) => {
    if (row.publishedToQuickbooks) return "Synced draws cannot be deleted";

    if (row.reviewStatus === "Paid") return "Paid draws cannot be deleted";

    return false;
  }, []);

  const select = useMemo<TableSelectAddon<InvoicesInvoice>>(
    () => ({
      value: invoiceSelectedDraws,
      onChange: setInvoiceSelectedDraws,
      isDisabled: selectIsDisabled,
    }),
    [selectIsDisabled, invoiceSelectedDraws, setInvoiceSelectedDraws]
  );

  const emptyState = useMemo(
    () => ({
      title: "",
      action: {
        onClick: invoiceCreate,
        children: `Create new ${INVOICE_STRINGS.LOWERCASE_INVOICE}`,
        disabled: !canManage || invoiceIsLoading,
      },
      subtitle: `You currently have no ${INVOICE_STRINGS.LOWERCASE_INVOICES}.`,
    }),
    [invoiceCreate, canManage, invoiceIsLoading]
  );

  const pagination = useMemo<TablePaginationAddon>(
    () => ({
      page,
      total: data.count,
      perPage,
      onChange: (page) => {
        scrollMainTop(0);
        setPage(page);
      },
      perPageVariant: "lg",
      onPerPageChange: (perPage) => {
        setPage(0);
        setPerPage(perPage);
        analytics.track("perPageLimitChange", {
          location: "invoices-table",
          limit: perPage,
        });
      },
    }),
    [page, data.count, perPage, setPage, setPerPage]
  );

  const header = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasDraws, sticky: true }),
    [hasDraws]
  );

  const columns: TableColumn<InvoicesInvoice>[] = useMemo(
    () => [
      {
        id: "doc_number",
        width: 215,
        name: `${INVOICE_STRINGS.INVOICE} #`,
        render: (row) => row.docNumber,
        visibility: "always-visible",
      },
      {
        id: "title",
        name: `Title`,
        width: 200,
        render: (row) => row.title,
      },
      {
        id: "project",
        name: "Project name",
        minWidth: 200,
        width: "fill",
        render: (row) => row.toName,
      },
      {
        id: "status",
        minWidth: 150,
        visibility: {
          name: "Sync status",
          mode: "visible",
        },
        name: (
          <Flex as={Text} weight="bold" gap="md" align="center">
            Sync status <QuickBooksSyncedIcon size={16} />
          </Flex>
        ),
        render: (row) => (
          <TagGroup data={getStatusTags(row)} color={getStatusColors(row)} />
        ),
      },
      {
        id: "date",
        width: 125,
        name: `${INVOICE_STRINGS.INVOICE} date`,
        render: (row) => formatDate(row.date, "MMM dd yyyy"),
      },
      {
        id: "due_date",
        width: 125,
        name: "Due date",
        render: (row) => formatDate(row.dueDate, "MMM dd yyyy"),
      },
      {
        id: "bills",
        name: "Linked transactions",
        width: 170,
        render: (row) => (
          <Link
            as="button"
            size="sm"
            type="button"
            onClick={() => setInvoice(row)}
            variant="success"
            data-testid="view-transactions"
          >
            <Text as="span" size="sm">
              View
            </Text>
          </Link>
        ),
      },
      {
        id: "amount",
        name: "Price",
        textAlign: "right",
        width: 140,
        render: (row) => formatCurrency(row.totalAmount, CURRENCY_FORMAT),
      },
    ],
    []
  );

  return (
    <>
      <Table
        row={row}
        data={data.results}
        size="sm"
        select={select}
        header={header}
        columns={columns}
        loading={isLoading}
        emptyState={emptyState}
        pagination={pagination}
        data-testid="invoices-table"
      />
      <InvoiceTransactionsDialog
        jobId={job.id}
        onClose={() => setInvoice(undefined)}
        invoiceId={invoice?.id}
      />
    </>
  );
};
