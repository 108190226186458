import type { Dispatch, SetStateAction } from "react";
import type { InvoicesInvoice } from "@api/invoices";
import type { GetCustomerCategoriesResponse } from "@api/jobs";
import { noop } from "@utils/noop";
import { createContext } from "use-context-selector";

import type { Line } from "./detail-view/budget/budgets-table/lines";
import type { Markup } from "./detail-view/budget/budgets-table/markups";

type JobContextType = {
  markups: Markup[];
  markupsIsLoading: boolean;
  settings: {
    categoriesEnabled: boolean;
    clientChangeTrackingEnabled: boolean;
    changeTrackingEnabled: boolean;
    ownersAmountEnabled: boolean;
  };
  categories: GetCustomerCategoriesResponse;
  refetchJob: () => Promise<void>;
  permissions: { canManage: boolean; canEditInitialBudget: boolean };
  invoiceCreate: () => Promise<void>;
  invoiceIsLoading: boolean;
  categoriesIsLoading: boolean;
  budgetSelectedLines: Line[];
  setBudgetSelectedLines: Dispatch<SetStateAction<Line[]>>;
  invoiceSelectedDraws: InvoicesInvoice[];
  setInvoiceSelectedDraws: Dispatch<SetStateAction<InvoicesInvoice[]>>;
};

export const JobContext = createContext<JobContextType>({
  markups: [],
  markupsIsLoading: false,
  settings: {
    categoriesEnabled: false,
    clientChangeTrackingEnabled: false,
    changeTrackingEnabled: false,
    ownersAmountEnabled: false,
  },
  categories: [],
  refetchJob: noop,
  permissions: { canManage: false, canEditInitialBudget: false },
  invoiceCreate: noop,
  invoiceIsLoading: false,
  categoriesIsLoading: false,
  budgetSelectedLines: [],
  setBudgetSelectedLines: noop,
  invoiceSelectedDraws: [],
  setInvoiceSelectedDraws: noop,
});
