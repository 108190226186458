import { toast } from "@adaptive/design-system";
import { dotObject } from "@adaptive/design-system/utils";
import {
  displayError,
  handleErrors,
  UNKNOWN_ERROR_MESSAGE,
} from "@api/handle-errors";
import { getUser } from "@api/users";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateUser } from "@store/user/slice";
import * as analytics from "@utils/analytics";

import { getClients } from "../../api/clients";
import { invitePeople } from "../../api/onboarding";
import {
  deleteInvitation as deleteInvitationRequest,
  deleteUser as deleteUserRequest,
  getPeople,
  resendInvitation as resendInvitationRequest,
} from "../../api/people";
import {
  sendSms2FA as sendSms2FARequest,
  verifySms2FA as verifySms2FARequest,
} from "../../api/users";
import { api } from "../../utils/api";
import { updateRealm } from "../realmSlice";
import { RootState } from "../types";

import { selectClientById } from "./selectors-memoized";
import {
  removePerson,
  updateClients,
  updatePeople,
  updatePersonData,
  updateTwoFactorAuthStatus,
} from "./slice";
import type {
  DeletePersonPayload,
  InvitePersonPayload,
  UpdatePersonPayload,
  Verify2FAPayload,
} from "./types";

type ReducerPayload = ReturnType<ReturnType<typeof selectClientById>>;

type ActionPayload = string;

type ThunkConfig = {
  state: RootState;
};

export const switchClient = createAsyncThunk<
  ReducerPayload,
  ActionPayload,
  ThunkConfig
>("user/switchClient", async (clientId, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;

  const client = selectClientById(getState())(clientId);

  if (client?.realm) {
    dispatch(updateRealm(client?.realm));
    getUser().then((user) => {
      if (!user) return Promise.reject("Failed retrieving User Data");
      dispatch(updateUser(user));
      return user;
    });
  }

  analytics.group(clientId, { name: client?.name });

  return selectClientById(getState())(clientId);
});

export const loadClients = createAsyncThunk(
  "user/load/clients",
  async (_actionPayload, { dispatch, rejectWithValue }) => {
    try {
      const clients = await getClients();
      dispatch(updateClients(clients));
    } catch (e) {
      rejectWithValue("Failed retrieving Clients Data");
    }
    return;
  }
);

export const loadPeople = createAsyncThunk(
  "user/load/people",
  async (clientId: string, { dispatch, rejectWithValue }) => {
    try {
      const people = await getPeople(clientId);
      dispatch(updatePeople(people));
    } catch (e) {
      rejectWithValue("Failed retrieving People Data");
    }
    return;
  }
);

export const updatePerson = createAsyncThunk<
  void,
  UpdatePersonPayload,
  ThunkConfig
>("user/updatePerson", (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;

  api
    .put(`/api/clients/${payload.clientId}/users/${payload.id}/`, {
      roles: [payload.role],
    })
    .then(() => {
      toast.success("User account updated");
      dispatch(
        updatePersonData({
          userId: payload.id,
          role: payload.role,
        })
      );
    });
});

export const deleteUser = createAsyncThunk<
  void,
  DeletePersonPayload,
  ThunkConfig
>("user/deleteUser", (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;

  deleteUserRequest(payload.clientId, payload.id).then(() => {
    toast.success("User deleted");
    dispatch(removePerson(payload.id));
  });
});

export const revokeInvitation = createAsyncThunk<
  void,
  DeletePersonPayload,
  ThunkConfig
>("user/revokeInvitation", (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;

  deleteInvitationRequest(payload.clientId, payload.id).then(() => {
    toast.success("Invitation revoked");
    dispatch(removePerson(payload.id));
  });
});

export const updateInvite = createAsyncThunk<
  void,
  UpdatePersonPayload,
  ThunkConfig
>("user/updateInvite", (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  api
    .put(`/api/clients/${payload.clientId}/invites/${payload.id}/`, {
      role: payload.role,
    })
    .then((response) => {
      toast.success("Invite updated");
      dispatch(updatePersonData({ userId: payload.id, ...response.data }));
    });
});

export const resendInvitation = createAsyncThunk<
  void,
  DeletePersonPayload,
  ThunkConfig
>("user/resendInvitation", (payload) => {
  resendInvitationRequest(payload.clientId, payload.id).then(() => {
    toast.success("Invitation sent");
  });
});

export const invitePerson = createAsyncThunk<
  void,
  InvitePersonPayload,
  ThunkConfig
>("user/invitePerson", async (payload, { dispatch }) => {
  try {
    await invitePeople({
      team_members: [payload],
      client_id: payload.clientId,
    });
    toast.success("Invite sent");
    dispatch(loadPeople(payload.clientId));
  } catch (e) {
    if (!handleErrors(e, { showUnknownErrorMessage: false })) {
      let errors = dotObject.get(e as object, "response.data", []);

      if (Array.isArray(errors)) {
        errors = errors.flatMap((item: unknown) =>
          typeof item === "object" && item ? Object.values(item) : []
        );

        if (!errors.length) {
          errors = [UNKNOWN_ERROR_MESSAGE];
        }
      } else {
        errors = [UNKNOWN_ERROR_MESSAGE];
      }

      displayError(errors);
    }

    throw new Error("Invite failed");
  }
});

export const sendSms2FA = createAsyncThunk("user/sendSms2FA", () => {
  return sendSms2FARequest();
});

export const verifySms2FA = createAsyncThunk<
  void,
  Verify2FAPayload,
  ThunkConfig
>("user/verifySms2FA", (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  return verifySms2FARequest(payload)
    .then(() => {
      dispatch(updateTwoFactorAuthStatus(true));
    })
    .catch((error) => {
      const status = error.response.status;
      switch (status) {
        case 400:
          throw new Error("Invalid code, try again");
        case 429:
          throw new Error(
            "You typed the wrong code too many times, try again in 10 minutes"
          );
        case 404:
          throw new Error("The code is no longer valid, send a new code");
        default:
          throw new Error("Unrecognized error");
      }
    });
});
