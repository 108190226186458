import React, { useCallback, useEffect, useMemo } from "react";
import type { ColumnOrderState, VisibilityState } from "@tanstack/react-table";
import forwardRefAs from "forward-ref-as";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { Button } from "../button";
import {
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownSelectableItem,
  type DropdownSelectableItemOnOrderChangeHandler,
  DropdownTrigger,
} from "../dropdown";
import { Icon } from "../icon";
import { Tooltip } from "../tooltip";

import { useTableContext } from "./table-context";
import { getLeafColumns } from "./utils";

const DEFAULT_COMPONENT = Button;

export const TableConfigurationButton = forwardRefAs<typeof DEFAULT_COMPONENT>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      color = "neutral",
      variant = "ghost",
      children,
      ...props
    },
    ref
  ) => {
    const {
      id,
      order,
      setOrder,
      columns = [],
      visibility = {},
      setVisibility,
    } = useTableContext();

    const value = useMemo(
      () =>
        Object.entries(visibility).reduce(
          (acc, [key, value]) => (value ? [...acc, key] : acc),
          [] as string[]
        ),
      [visibility]
    );

    const enhancedProps = { ...props, color: color, variant: variant };

    const leafColumns = useMemo(() => getLeafColumns(columns), [columns]);

    const data = useDeepMemo(
      () =>
        leafColumns
          .map((column) => ({
            label:
              typeof column.visibility === "object"
                ? column.visibility.name
                : column.name,
            value: column.id,
            disabled:
              (typeof column.visibility === "object"
                ? column.visibility.mode
                : column.visibility) === "always-visible",
          }))
          .sort((a, b) => {
            const orderA = order?.indexOf(a.value) ?? 0;
            const orderB = order?.indexOf(b.value) ?? 0;
            return orderA - orderB;
          }),
      [order, leafColumns]
    );

    const enhancedSetOrder = useCallback(
      (value: string[]) => {
        setOrder?.(value);
        localStorage.setItem(`${id}-table-order`, JSON.stringify(value));
      },
      [id, setOrder]
    );

    const setValue = useCallback(
      (value: string[]) => {
        const visibility = {
          ...data.reduce(
            (acc, item) => ({ ...acc, [item.value]: false }),
            {} as VisibilityState
          ),
          ...value.reduce(
            (acc, column) => ({ ...acc, [column]: true }),
            {} as VisibilityState
          ),
        };

        localStorage.setItem(
          `${id}-table-visibility`,
          JSON.stringify(visibility)
        );
        setVisibility?.(visibility);
      },
      [id, data, setVisibility]
    );

    const onReset = useEvent(() => {
      const visibility = leafColumns.reduce(
        (acc, column) => ({
          ...acc,
          [column.id]:
            (typeof column.visibility === "object"
              ? column.visibility.mode
              : column.visibility) !== "hidden",
        }),
        {} as VisibilityState
      );

      localStorage.removeItem(`${id}-table-order`);
      localStorage.removeItem(`${id}-table-visibility`);
      setOrder?.(Object.keys(visibility));
      setVisibility?.(visibility);
    });

    const onOrderChange = useEvent<DropdownSelectableItemOnOrderChangeHandler>(
      (value) => enhancedSetOrder(value.map((item) => item.value))
    );

    useEffect(() => {
      if (leafColumns.length === 0) return;

      const orderCache = localStorage.getItem(`${id}-table-order`);
      const visibilityCache = localStorage.getItem(`${id}-table-visibility`);

      let order = leafColumns.map((column) => column.id);
      let visibility = leafColumns.reduce(
        (acc, column) => ({
          ...acc,
          [column.id]:
            (typeof column.visibility === "object"
              ? column.visibility.mode
              : column.visibility) !== "hidden",
        }),
        {} as VisibilityState
      );

      if (orderCache) {
        const parsedOrderCache = JSON.parse(orderCache) as ColumnOrderState;
        let mergedCache: ColumnOrderState = [];

        order.forEach((column) => {
          if (!parsedOrderCache.includes(column)) {
            // This is a new column, append it to mergedCache
            mergedCache.push(column);
          } else {
            // This column is in the cache, find the correct position in mergedCache
            const correctPosition = mergedCache.findIndex(
              (item) =>
                parsedOrderCache.indexOf(item) >
                parsedOrderCache.indexOf(column)
            );
            if (correctPosition !== -1) {
              // Insert the column at the correct position
              mergedCache = [
                ...mergedCache.slice(0, correctPosition),
                column,
                ...mergedCache.slice(correctPosition),
              ];
            } else {
              // If the column is not found in mergedCache, append it
              mergedCache.push(column);
            }
          }
        });

        order = mergedCache;
      }

      if (visibilityCache) {
        const parsedVisibilityCache = JSON.parse(
          visibilityCache
        ) as VisibilityState;
        const mergedVisibility: VisibilityState = {};

        Object.keys(visibility).forEach((column) => {
          if (column in parsedVisibilityCache) {
            // This column is in the visibility object, update its visibility
            mergedVisibility[column] = parsedVisibilityCache[column];
          } else {
            // This column is not in the visibility object, append it
            mergedVisibility[column] = visibility[column];
          }
        });

        visibility = mergedVisibility;
      }

      enhancedSetOrder(order);
      setVisibility?.(visibility);
    }, [id, leafColumns, enhancedSetOrder, setVisibility]);

    const EnhancedComponent = useMemo(
      () =>
        forwardRefAs<typeof DEFAULT_COMPONENT, typeof enhancedProps>(
          (props, ref) => (
            <Tooltip
              as={Component}
              ref={ref}
              message={!children ? "Configure table" : ""}
              placement="top"
              aria-label="Configure table"
              {...props}
            >
              {children ?? <Icon name="gear" />}
            </Tooltip>
          )
        ),
      [children, Component]
    );

    return (
      <Dropdown
        flip={false}
        listSize={5}
        placement="bottom-end"
        hideOnClick={false}
      >
        <DropdownTrigger ref={ref} as={EnhancedComponent} {...enhancedProps} />
        <DropdownList>
          <DropdownItem onClick={onReset}>Reset columns</DropdownItem>
          <DropdownSelectableItem
            name="table-configuration"
            data={data}
            value={value}
            multiple
            onChange={setValue}
            onOrderChange={onOrderChange}
          />
        </DropdownList>
      </Dropdown>
    );
  }
);
