import React, { type ReactNode } from "react";
import { Text } from "@adaptive/design-system";
import cn from "clsx";

import styles from "./main.module.css";

type Props = { className?: string; children?: ReactNode; truncate?: boolean };

export const MainTitle = ({ className, ...props }: Props) => (
  <Text
    as="h2"
    size="2xl"
    weight="bold"
    className={cn(styles["main-title"], className)}
    {...props}
  />
);
