import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  ComboBox,
  CurrencyField,
  Flex,
  Image,
  Link,
  Loader,
  Text,
  TextField,
  toast,
  Tooltip,
  Wrapper,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useLocalStorageState,
} from "@adaptive/design-system/hooks";
import { useMultiStepDialog } from "@adaptive/design-system/hooks";
import { formatCurrency, parseCurrency } from "@adaptive/design-system/utils";
import { getBankAccounts, patchAccountBalance } from "@api/bank-accounts";
import { cancelBillPayment, storeBillPayment } from "@api/bill-payments";
import { handleErrors } from "@api/handle-errors";
import { ErrorAlert } from "@components/common/error-alert";
import { ExpiredDocumentsAlert } from "@components/common/expired-documents-alert";
import { TooManyLinesAlert } from "@components/common/too-many-lines-alert";
import { DuplicateAlert } from "@components/duplicate-alert";
import { Form } from "@components/form";
import { RequestVendorAch } from "@components/request-vendor-ach";
import { RequestedVendorDocumentAlert } from "@components/request-vendor-document";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { BillLienWaiverField } from "@lien-waiver/components";
import { LIEN_WAIVER_LINKED_STATUS } from "@lien-waiver/constants";
import signatureFont from "@shared/assets/fonts/signature.woff2";
import { loadFont } from "@shared/utils/font-loader";
import {
  useBillFormActions,
  useBillFormPermissions,
} from "@src/bills/bill-form-context";
import {
  ACH_PROCESSING_BILL_ALERT_CONTENT,
  BILL_STATUS,
  getTransactionType,
  PAY_BUTTON_LABEL,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  STRINGS,
} from "@src/bills/constants";
import {
  approvalsBillSelector,
  camelCaseBillSelector,
  defaultBankAccountBillSelector,
  duplicatesBillSelector,
  errorsBillSelector,
  isDirtyBillSelector,
  isInPaymentStatusBill,
  paymentsBillSelector,
  relatedErrorsBillSelector,
  vendorBillSelector,
  workflowsBillSelector,
} from "@src/bills/utils";
import { AccountSelectDialog } from "@src/settings/components/account-select-dialog";
import { fetchAllBills, fetchForPaymentBills } from "@store/billListSlice";
import {
  recordBillUpdate,
  refetchCurrentBill,
  setBillDefaultLienWaiver,
  unarchiveCurrentBill,
} from "@store/billSlice";
import { loadBill } from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useTwoFactorAuth } from "@store/ui";
import { useClientInfo, useClientSettings, useUserInfo } from "@store/user";
import { useVendorAction, useVendorInfo } from "@store/vendors";
import * as analytics from "@utils/analytics";
import { sum } from "@utils/sum";
import lodashIsEmpty from "lodash.isempty";

import { ApprovalsSection } from "../approvals-section";
import { Comments } from "../comments";
import { useCycle } from "../cycle-provider";
import { DynamicActions } from "../dynamic-actions";
import { Info } from "../info";
import { Items } from "../items";
import { PurchaseOrders, PurchaseOrdersAlert } from "../purchase-orders";
import { PurchaseOrdersOverBudgetAlert } from "../purchase-orders/purchase-orders-over-budget-alert";
import { UpdateSignatureDialog } from "../update-signature-dialog";

import { BillPaymentInfo } from "./bill-payment-info";
import {
  getAccountBalanceLabel,
  getAchProcessingOption,
  getPaymentMethods,
  getSignatureString,
  selectVendorPaymentData,
} from "./utils";
import {
  REASONS,
  validatePayBillAccountBalance,
  validatePayBillClient,
  validatePayBillForm,
  validatePayBillVendor,
} from "./validation";

export const PayBillStepForm = () => {
  const { save, close: onClose } = useBillFormActions();

  const cycle = useCycle();

  const { user } = useUserInfo();

  const { canManageLienWaivers } = useClientSettings();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const permissions = useBillFormPermissions();

  const {
    id,
    url: billUrl,
    balance,
    docNumber,
    realm: billRealm,
    isArchivedByUser,
    linesCount,
    amountToPay,
    tooManyLines,
    reviewStatus,
    isVendorCredit,
    totalAmount,
    billLienWaiverTemplate,
    lienWaivers,
  } = useAppSelector(camelCaseBillSelector);

  const errors = useAppSelector(errorsBillSelector);

  const relatedErrors = useAppSelector(relatedErrorsBillSelector);

  const isDirty = useAppSelector(isDirtyBillSelector);

  const approvals = useAppSelector(approvalsBillSelector);

  const workflows = useAppSelector(workflowsBillSelector);

  const billVendor = useAppSelector(vendorBillSelector);

  const duplicates = useAppSelector(duplicatesBillSelector);

  const billPayments = useAppSelector(paymentsBillSelector);

  const { drawerOpen } = useVendorInfo();

  const defaultBankAccounts = useAppSelector(defaultBankAccountBillSelector);

  const lienWaiver = useMemo(
    () => lienWaivers.find((item) => !item.billPayment),
    [lienWaivers]
  );

  const {
    vendor,
    achOptions: bankingACHS,
    addressOptions: vendorAddresses,
    recipientOptions: vendors,
    phoneNumberOptions: vendorPhoneNumbers,
  } = useAppSelector(selectVendorPaymentData);

  const { checkTwoFactorAuth } = useTwoFactorAuth();

  const [formErrors, setFormErrors] = useState([]);

  const [currentSignature, setSignature] = useState({});

  const { realmId, client: currentClient } = useClientInfo();

  const [accountBalances, setAccountBalances] = useState([]);

  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const [cancellingPayment, setCancellingPayment] = useState(false);
  const [cancelledPaymentId, setCancelledPaymentId] = useState(null);

  const { fetchById: fetchVendor, showVendorById } = useVendorAction();

  const [payingAccountErrors, setPayingAccountErrors] = useState([]);

  const [receivingAccountErrors, setReceivingAccountErrors] = useState([]);

  const goToSettings = useEvent(() =>
    navigate("/settings/company/bank-accounts")
  );
  const transactionType = useMemo(
    () => getTransactionType(isVendorCredit),
    [isVendorCredit]
  );

  const hasSignature = !lodashIsEmpty(currentSignature);

  const paymentMethods = useMemo(
    () => getPaymentMethods(currentClient.settings),
    [currentClient.settings]
  );

  const getSignature = useCallback(
    (signatureName) => {
      const font = "56px 'Kristi', cursive";
      const content = signatureName || user.full_name;
      const contentHeight = 30;
      const contentStartOffset = 10;
      const contentEndOffset = 10;
      const contentBottomOffset = 10;

      // We need to create a "virtual element" to get rendered dimensions
      const el = document.createElement("div");
      el.innerHTML = content;
      el.style.font = font;
      el.style.width = "max-content";
      document.documentElement.append(el);
      const { width, height } = el.getBoundingClientRect();
      document.documentElement.removeChild(el);

      const canvas = document.createElement("canvas");
      canvas.width = width + contentStartOffset + contentEndOffset;
      canvas.height = height + contentBottomOffset;
      const ctx = canvas.getContext("2d");
      ctx.font = font;
      ctx.fillText(content, contentStartOffset, height / 2 + contentHeight / 2);

      return { url: canvas.toDataURL(), name: content, width, height };
    },
    [user.full_name]
  );

  const curriedOpenVendor = useCallback(
    (stage) => () => {
      showVendorById(billVendor.id, stage);
    },
    [billVendor.id, showVendorById]
  );

  const validBillPayments = useMemo(
    () =>
      billPayments.filter(
        (payment) =>
          payment.status !== PAYMENT_STATUS.CANCELLED &&
          payment.status !== PAYMENT_STATUS.FAILED
      ) || [],
    [billPayments]
  );

  const isPaid = parseCurrency(balance) === 0;

  const isInPaymentStatus = isInPaymentStatusBill(reviewStatus);

  const singlePayment =
    isPaid && validBillPayments.length === 1 && validBillPayments[0];

  const enhancedCanPay = useMemo(
    () => permissions.canPayBill && !isPaid,
    [permissions.canPayBill, isPaid]
  );

  const accountSelectDialog = useMultiStepDialog({
    initialStep: "set-account",
  });

  const showSelectAccountDialog = () => {
    accountSelectDialog.show();
  };

  const updateSignatureDialog = useDialog();

  const [payment, setPayment] = useLocalStorageState(
    `bill-${id}-payment-step`,
    {
      method: window.ACH_CHECK_ENABLED
        ? PAYMENT_METHOD.ACH
        : PAYMENT_METHOD.MARK_AS_PAID,
      vendor: vendor.email || vendors[0]?.value || "",
      accountBalance: "",
      account: "",
      vendorBankingACH: vendor?.banking?.url || "",
      vendorEmail: vendor.email || "",
      vendorAddress: vendor?.address?.url || "",
      vendorPhoneNumber: vendor.phoneNumber || "",
      signature: currentSignature?.url || "",
    }
  );

  const paymentVendorEmail = useMemo(
    () =>
      payment.vendorEmail ||
      (payment.method == PAYMENT_METHOD.ACH &&
        billVendor.pending_ach_request?.vendor_email) ||
      "",
    [payment, billVendor]
  );

  const hasPrintCheck =
    isPaid &&
    singlePayment?.checks?.length > 0 &&
    singlePayment?.internal_method == PAYMENT_METHOD.PRINT_CHECK;

  const getAccountBalanceObject = useCallback(
    (url) => {
      return accountBalances.find((balance) => balance.url === url);
    },
    [accountBalances]
  );

  const fromAccountValidation = useMemo(() => {
    if (isPaid) return;
    if (payment.accountBalance) {
      return validatePayBillAccountBalance(
        getAccountBalanceObject(payment.accountBalance)
      );
    }
    return validatePayBillClient(accountBalances);
  }, [
    accountBalances,
    isPaid,
    getAccountBalanceObject,
    payment.accountBalance,
  ]);

  const formattedBalances = useMemo(
    () =>
      accountBalances.map((accountBalance) => {
        return {
          label: getAccountBalanceLabel(accountBalance),
          value: accountBalance.url,
          paymentAccount: accountBalance.payment_account?.url,
        };
      }),
    [accountBalances]
  );

  const onUnarchive = useEvent(() => {
    cycle.disable();
    dispatch(unarchiveCurrentBill());
  });

  const refetchBill = useCallback(() => dispatch(loadBill(id)), [dispatch, id]);

  const onCancelPayment = useEvent(async (billPaymentId) => {
    setCancellingPayment(true);

    try {
      await cancelBillPayment(billPaymentId, id);
    } catch (e) {
      if (!handleErrors(e)) throw e;
      setCancellingPayment(false);
      refetchBill();
    } finally {
      setCancelledPaymentId(billPaymentId);
      refetchBill();

      const cancelledPayment = billPayments.find(
        (payment) => payment.id === billPaymentId
      );

      if (cancelledPayment.is_voided) {
        setCancellingPayment(false);
      }
    }
  });

  useEffect(() => {
    if (cancelledPaymentId && cancellingPayment) {
      refetchBill();
      const cancelledPayment = billPayments.find(
        (payment) => payment.id === cancelledPaymentId
      );
      if (cancelledPayment.is_voided) {
        setCancelledPaymentId(null);
        setCancellingPayment(false);
      }
    }
  }, [cancelledPaymentId, cancellingPayment, billPayments, refetchBill]);

  const onViewPayment = useCallback(
    (id) =>
      window.open(`/api/billpayments/${id}/check/?realm=${realmId}`, "_blank"),
    [realmId]
  );

  const billAlert = useMemo(() => {
    if (reviewStatus === BILL_STATUS.PAID) return;

    const getContent = () => {
      if (reviewStatus === BILL_STATUS.ACH_PROCESSING) {
        return ACH_PROCESSING_BILL_ALERT_CONTENT(
          getAchProcessingOption(currentClient.settings.ach_processing_time)
            .description
        );
      } else if (reviewStatus === BILL_STATUS.ACH_INFO_REQUESTED) {
        return STRINGS.ACH_INFO_REQUESTED_BILL_ALERT_CONTENT;
      } else if (isArchivedByUser && permissions.canEditBill) {
        return (
          <>
            <Link as="button" type="button" onClick={onUnarchive}>
              Restore this {transactionType}
            </Link>{" "}
            in order to make any modifications to it
          </>
        );
      }
    };

    const content = getContent();

    if (!content) return;

    return (
      <Alert variant="info">
        <AlertTitle>This {transactionType} is not editable</AlertTitle>
        <AlertContent>{content}</AlertContent>
      </Alert>
    );
  }, [
    reviewStatus,
    transactionType,
    isArchivedByUser,
    permissions.canEditBill,
    currentClient.settings.ach_processing_time,
    onUnarchive,
  ]);

  const curriedOnPay = (makePayment) => () => {
    checkTwoFactorAuth(async () => {
      setPaymentProcessing(true);
      try {
        const data = await storeBillPayment({
          client: currentClient.id,
          linked_transaction: billUrl,
          realm: billRealm,
          vendor: payment.vendor,
          account:
            payment.method === PAYMENT_METHOD.MARK_AS_PAID
              ? payment.account
              : null,
          total_amount: Number(amountToPay),
          account_balance:
            payment.method == PAYMENT_METHOD.MARK_AS_PAID
              ? null
              : payment.accountBalance,
          internal_method: payment.method,
          vendor_banking_ach:
            payment.method === PAYMENT_METHOD.ACH
              ? payment.vendorBankingACH
              : null,
          vendor_email:
            payment.method === PAYMENT_METHOD.ACH
              ? paymentVendorEmail
              : payment.method === PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK
                ? payment.vendorEmail
                : null,
          make_payment: makePayment,
          vendor_address:
            payment.method === PAYMENT_METHOD.MAIL_CHECK
              ? payment.vendorAddress
              : null,
          vendor_phone_number:
            payment.method === PAYMENT_METHOD.VIRTUAL_SMS_CHECK
              ? payment.vendorPhoneNumber
              : null,
          ...([
            PAYMENT_METHOD.MAIL_CHECK,
            PAYMENT_METHOD.VIRTUAL_SMS_CHECK,
            PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK,
          ].includes(payment.method)
            ? { signature: getSignatureString(payment.signature) }
            : {}),
        });

        const shouldNavigate = cycle.status && cycle.status !== "all";

        await Promise.all([
          fetchVendor(billVendor.id),
          shouldNavigate ? Promise.resolve() : dispatch(fetchForPaymentBills()),
          dispatch(fetchAllBills()),
        ]);

        if (makePayment && payment.method === PAYMENT_METHOD.PRINT_CHECK) {
          onViewPayment(data.id);
        }

        toast.success(`You've successfully paid Bill #${docNumber}!`);
        analytics.track("billPay", { billId: id });

        if (!shouldNavigate) return refetchBill();

        const hasNext = cycle.hasNavigation && (await cycle.next());

        if (hasNext) return;

        navigate("/bills?status=for-payment");

        toast.success("You paid all bills waiting for payment");
      } catch (e) {
        handleErrors(e);
      } finally {
        setPaymentProcessing(false);
      }
    });
  };

  const getPayingAccountActionButton = () => {
    const onClick =
      payingAccountErrors[0] === REASONS.BANK_ACCOUNT_NOT_LINKED
        ? showSelectAccountDialog
        : goToSettings;
    const message =
      payingAccountErrors[0] === REASONS.BANK_ACCOUNT_NOT_LINKED
        ? "link accounts"
        : "add missing information";
    return (
      <Flex gap="sm" margin={["none", "none", "lg"]}>
        <Text size="sm" color="neutral-700" weight="regular">
          Do you need to {message}?
        </Text>
        <Link as="button" type="button" variant="success" onClick={onClick}>
          Click here
        </Link>
      </Flex>
    );
  };

  const paymentAccounts = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  const hasValidationErrors =
    payingAccountErrors.length > 0 ||
    receivingAccountErrors.length > 0 ||
    formErrors.length > 0;

  const isMarkAsPaidEnabled =
    payment.method === PAYMENT_METHOD.MARK_AS_PAID && payment.account;

  const isPrintCheckEnabled =
    payment.method === PAYMENT_METHOD.PRINT_CHECK && payment.accountBalance;

  const isACHEnabled = !!(
    payment.method === PAYMENT_METHOD.ACH &&
    payment.accountBalance &&
    paymentVendorEmail
  );

  const isVirtualCheckEnabled = !!(
    payment.method === PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK &&
    payment.accountBalance &&
    payment.vendorEmail &&
    payment.signature
  );

  const isMailCheckEnabled = !!(
    payment.method === PAYMENT_METHOD.MAIL_CHECK &&
    payment.accountBalance &&
    payment.vendorAddress &&
    payment.signature
  );

  const isSMSCheckEnabled = !!(
    payment.method === PAYMENT_METHOD.VIRTUAL_SMS_CHECK &&
    payment.accountBalance &&
    payment.vendorPhoneNumber
  );

  const isPaymentEnabled =
    isMarkAsPaidEnabled ||
    (!hasValidationErrors &&
      (isPrintCheckEnabled ||
        isACHEnabled ||
        isVirtualCheckEnabled ||
        isMailCheckEnabled ||
        isSMSCheckEnabled));

  const notAllowedToPayTooltip = !enhancedCanPay
    ? STRINGS.ACTION_NOT_ALLOWED
    : undefined;
  const billAlreadyPaidTooltip =
    reviewStatus === BILL_STATUS.PAID
      ? "This bill has already been paid"
      : undefined;
  const accountBalanceRequiredTooltip =
    payment.method !== PAYMENT_METHOD.MARK_AS_PAID && !payment.accountBalance
      ? "Select an account from which to pay"
      : undefined;
  const paymentAccountRequiredTooltip =
    payment.method === PAYMENT_METHOD.MARK_AS_PAID && !payment.account
      ? "Select an account from which this bill was paid"
      : undefined;

  const recipientEmailBoxErrorMessage =
    vendor.isStoredEmailValid && enhancedCanPay
      ? payment.method !== PAYMENT_METHOD.ACH
        ? receivingAccountErrors?.[0]
        : formErrors?.[0]
      : undefined;

  const recipientEmailField = (
    <Flex gap="sm" direction="column" width="full">
      <TextField
        type="email"
        disabled
        label="Recipient's email"
        errorMessage={recipientEmailBoxErrorMessage}
        helperMessage={
          !vendor.isStoredEmailValid
            ? "Invalid email, please edit directly in QuickBooks"
            : undefined
        }
        messageVariant={
          recipientEmailBoxErrorMessage || !vendor.isStoredEmailValid
            ? "relative"
            : "absolute"
        }
        value={paymentVendorEmail}
        onChange={(value) =>
          setPayment((previousPayment) => ({
            ...previousPayment,
            vendorEmail: value,
          }))
        }
      />
      {vendor.isStoredEmailValid && (
        <Flex gap="sm" margin={["none", "none", "lg"]}>
          <Text size="sm" color="neutral-700" weight="regular">
            Do you need to {paymentVendorEmail ? "change" : "add"} the
            vendor&apos;s email?
          </Text>
          <Link
            as="button"
            type="button"
            variant="success"
            onClick={curriedOpenVendor("info")}
          >
            Edit it here
          </Link>
        </Flex>
      )}
    </Flex>
  );

  const fromAccountComboBox = (
    <Flex gap="sm" direction="column">
      <ComboBox
        disabled={isArchivedByUser || !enhancedCanPay}
        errorMessage={enhancedCanPay ? payingAccountErrors?.[0] : undefined}
        data={formattedBalances}
        label="From account"
        value={payment.accountBalance}
        data-testid="from-account"
        onChange={(value) =>
          setPayment((previousPayment) => ({
            ...previousPayment,
            accountBalance: value,
          }))
        }
      />
      {payingAccountErrors.length > 0 && getPayingAccountActionButton()}
    </Flex>
  );

  const signatureField = reviewStatus === BILL_STATUS.FOR_PAYMENT &&
    hasSignature && (
      <>
        <Flex direction="column">
          <Text size="sm">Signature</Text>
          <Flex padding={["xl", "none"]}>
            <Image
              src={currentSignature.url}
              width={currentSignature.width}
              height={currentSignature.height}
            />
          </Flex>
          <Flex size="sm">
            <Button
              variant="ghost"
              color="primary"
              onClick={updateSignatureDialog.show}
            >
              Update signature
            </Button>
          </Flex>
        </Flex>
        <UpdateSignatureDialog
          dialog={updateSignatureDialog}
          signature={currentSignature.name}
          defaultSignature={user.full_name}
          onChange={(value) => {
            setSignature(getSignature(value));
          }}
        />
      </>
    );

  const hasLoader = (label) =>
    paymentProcessing || cancellingPayment ? <Loader /> : label;

  const getPrimaryActionButtons = () => {
    let buttonProps = {
      onClick: curriedOnPay(makePayment),
      disabled:
        isArchivedByUser ||
        !isPaymentEnabled ||
        paymentProcessing ||
        !enhancedCanPay ||
        reviewStatus === BILL_STATUS.PAID,
      children: hasLoader(PAY_BUTTON_LABEL.DEFAULT),
    };

    let tooltipMessage =
      notAllowedToPayTooltip ||
      billAlreadyPaidTooltip ||
      accountBalanceRequiredTooltip ||
      paymentAccountRequiredTooltip;

    if (isArchivedByUser) {
      buttonProps = {
        onClick: onUnarchive,
        children: "Restore",
        disabled: false,
      };
    } else if (
      isPaid &&
      singlePayment?.internal_method === PAYMENT_METHOD.PRINT_CHECK &&
      singlePayment?.checks?.length
    ) {
      buttonProps = {
        onClick: () => onViewPayment(singlePayment.id),
        disabled: !hasPrintCheck,
        children: "View check",
      };
    } else if (isVendorCredit) {
      buttonProps = {
        onClick: onClose,
        disabled: false,
        children: "Close",
      };
      tooltipMessage = null;
      // bill is not archived and isn't vendor credit
    } else if (payment.method == PAYMENT_METHOD.MARK_AS_PAID) {
      buttonProps = {
        ...buttonProps,
        children: hasLoader(PAY_BUTTON_LABEL.MARK_AS_PAID),
      };
    } else if (payment.method === PAYMENT_METHOD.ACH) {
      if (reviewStatus === BILL_STATUS.ACH_INFO_REQUESTED) {
        buttonProps = {
          disabled: false,
          onClick: () => onCancelPayment(singlePayment?.id),
          children: hasLoader("Cancel payment"),
        };
        tooltipMessage = !permissions.canPayBill && STRINGS.ACTION_NOT_ALLOWED;
      } else {
        if (ACHPaymentHasNoACH) {
          buttonProps = {
            ...buttonProps,
            children: hasLoader(PAY_BUTTON_LABEL.ACH_PAYMENT_REQUESTED),
          };
        }
      }
    }

    if (isDirty) {
      buttonProps.disabled = true;
      tooltipMessage =
        "You have unsaved changes. Save changes first to proceed with payment";
    }

    const content = <Button {...buttonProps} size="lg" />;

    return tooltipMessage ? (
      <Tooltip message={tooltipMessage} placement="left">
        {content}
      </Tooltip>
    ) : (
      content
    );
  };
  const ACHPaymentHasNoACH =
    payment.method == PAYMENT_METHOD.ACH &&
    // if user adds ACH then it's a regular ACH payment
    !payment.vendorBankingACH;

  const makePayment = !(
    payment.method == PAYMENT_METHOD.MARK_AS_PAID || ACHPaymentHasNoACH
  );

  const onSave = useEvent(() => save());

  const onChangePaymentAmount = useEvent((value) =>
    dispatch(recordBillUpdate({ amount_to_pay: value }))
  );

  const onChangeLienWaiverTemplate = useEvent((value) =>
    dispatch(setBillDefaultLienWaiver(value))
  );

  const onChangeLienWaiverRequest = useEvent(() =>
    dispatch(refetchCurrentBill(["lien_waivers"]))
  );

  const haveLinkedLienWaiver =
    lienWaiver &&
    LIEN_WAIVER_LINKED_STATUS.some((status) => status === lienWaiver.status);

  useEffect(() => {
    if ((!vendor || billVendor.id != vendor.id) && !drawerOpen) {
      fetchVendor(billVendor.id);
    }
  }, [billVendor.id, drawerOpen, fetchVendor, vendor]);

  useEffect(() => {
    if (isPaid) return;

    setPayment((prevState) => {
      const hasChangedVendor =
        prevState.vendorEmail !== vendor.email ||
        prevState.vendorPhoneNumber !== vendor.phoneNumber ||
        prevState.vendorBankingACH !== vendor?.banking?.url ||
        prevState.vendor !== vendor.url ||
        prevState.vendorAddress !== vendor?.address?.url;

      return {
        ...prevState,
        accountBalance:
          prevState.accountBalance ||
          defaultBankAccounts?.[0] ||
          accountBalances[0]?.url ||
          "",
        vendorEmail:
          (hasChangedVendor ? vendor.email : prevState.vendorEmail) || "",
        vendorPhoneNumber:
          (hasChangedVendor
            ? vendor.phoneNumber
            : prevState.vendorPhoneNumber) || "",
        vendorBankingACH:
          (hasChangedVendor
            ? vendor?.banking?.url
            : prevState.vendorBankingACH) || "",
        vendor: (hasChangedVendor ? vendor.url : prevState.vendor) || "",
        vendorAddress:
          (hasChangedVendor ? vendor?.address?.url : prevState.vendorAddress) ||
          "",
        signature: prevState.signature || currentSignature?.url || "",
      };
    });
  }, [
    vendor,
    isPaid,
    setPayment,
    accountBalances,
    currentSignature,
    defaultBankAccounts,
  ]);

  // @todo: use useBankAccounts hook instead?
  useEffect(() => {
    if (currentClient) {
      getBankAccounts({
        client: currentClient.id,
        refresh_balance: false,
        verification_status: ["manually_verified", "automatically_verified"],
      }).then((response) => {
        return setAccountBalances(
          response.results.flatMap((client) => {
            return client.bank_accounts
              .filter((bank) => bank.type === "depository")
              .flatMap((bankAccount) => {
                return bankAccount.account_balances
                  .filter((balance) => balance.checkbook_account_is_active)
                  .map((balance) => {
                    return {
                      ...balance,
                    };
                  });
              });
          })
        );
      });
    }
  }, [currentClient, accountSelectDialog.isVisible]);

  useEffect(() => {
    if (fromAccountValidation && !fromAccountValidation.ok) {
      setPayingAccountErrors(fromAccountValidation.failures);
    } else {
      setPayingAccountErrors([]);
    }
  }, [fromAccountValidation]);

  useEffect(() => {
    if (!isPaid) {
      const { ok, failures } = validatePayBillVendor({
        paymentMethod: payment.method,
        vendor,
      });
      if (!ok) return setReceivingAccountErrors(failures);
      setReceivingAccountErrors([]);
    }
  }, [payment.method, vendor, isPaid]);

  useEffect(() => {
    if (!isPaid) {
      const { ok, failures } = validatePayBillForm({
        paymentMethod: payment.method,
        vendorEmail: paymentVendorEmail,
      });

      if (!ok) return setFormErrors(failures);
    }
    setFormErrors([]);
  }, [payment, isPaid, paymentVendorEmail]);

  useEffect(() => {
    loadFont({ family: "Kristi", url: signatureFont }).then(() => {
      if (!hasSignature) {
        setSignature(getSignature());
      }
    });
  }, [hasSignature, getSignature]);

  return (
    <>
      <AccountSelectDialog
        dialog={accountSelectDialog}
        selectHeader={`Link your bank account (••••${
          getAccountBalanceObject(payment?.accountBalance)?.mask
        }) to an account in your accounting software`}
        putObject={(values) => {
          patchAccountBalance({
            account: getAccountBalanceObject(payment?.accountBalance),
            ...values,
          });
        }}
        isBankAccount={true}
        selectedAccount={null}
      />
      <div className="steps-section-content">
        <Flex gap="5xl" direction="column" padding={["3xl", "5xl"]}>
          <Flex gap="xl" direction="column">
            {permissions.canEditBill && billAlert}

            {duplicates.length > 0 && <DuplicateAlert data={duplicates} />}

            <PurchaseOrdersAlert />

            <TooManyLinesAlert transactionType="Bill" linesCount={linesCount} />

            {(errors.length > 0 || relatedErrors.length > 0) && (
              <ErrorAlert
                data={errors}
                onChange={refetchBill}
                objectType="Bill"
                relatedData={relatedErrors}
              />
            )}

            <ExpiredDocumentsAlert vendor={billVendor} />

            <RequestedVendorDocumentAlert vendor={billVendor} />

            <Form onEnterSubmit={onSave}>
              <Info />
            </Form>
          </Flex>

          {isVendorCredit || isArchivedByUser ? null : (
            <Flex gap="xl" direction="column">
              <Text size="xl" weight="bold">
                Payment details
              </Text>

              <PurchaseOrdersOverBudgetAlert />

              <Wrapper
                when={
                  currentClient.settings.card_feed_enabled &&
                  billPayments?.length > 0
                }
                render={(children) => (
                  <Flex
                    gap="2xl"
                    direction="column"
                    separator
                    padding={["none", "none", "xl"]}
                  >
                    {children}
                  </Flex>
                )}
              >
                {billPayments.map((payment) => (
                  <BillPaymentInfo
                    key={payment.id}
                    loading={cancellingPayment}
                    billPayment={payment}
                    onViewPayment={onViewPayment}
                    onCancelPayment={onCancelPayment}
                  />
                ))}
              </Wrapper>

              {!isPaid && (
                <>
                  <Flex gap="xl">
                    <Flex direction="column" width="full">
                      <Text size="sm">Payment amount</Text>
                      <CurrencyField
                        aria-labelledby="amount-to-pay-label"
                        value={amountToPay}
                        disabled={haveLinkedLienWaiver || !enhancedCanPay}
                        helperMessage={
                          haveLinkedLienWaiver &&
                          STRINGS.PAYMENT_AMOUNT_DISABLED_LIEN_WAIVER
                        }
                        onChange={onChangePaymentAmount}
                        autoFocus
                        triggerChangeOnFocusedUnmount={false}
                        allowNegative={false}
                        data-testid="payment-amount"
                        errorMessage={
                          amountToPay > totalAmount &&
                          STRINGS.PAYMENT_AMOUNT_T0_HIGH
                        }
                      />
                    </Flex>

                    <Flex direction="column" width="full">
                      <Text size="sm">Bill balance after payment</Text>
                      <Text weight="bold">
                        {formatCurrency(
                          sum(parseCurrency(balance), -amountToPay),
                          {
                            currencySign: true,
                            allowNegative: true,
                          }
                        )}
                      </Text>
                    </Flex>
                  </Flex>

                  {canManageLienWaivers && balance > 0 && (
                    <BillLienWaiverField
                      value={billLienWaiverTemplate}
                      onChange={onChangeLienWaiverTemplate}
                      recipientEmail={paymentVendorEmail}
                      vendor={vendor}
                      paymentAmount={amountToPay}
                      billId={id}
                      billLienWaiver={lienWaiver}
                      onRequestUpdate={onChangeLienWaiverRequest}
                    />
                  )}

                  <Flex direction="column">
                    <ComboBox
                      className="pay-bill-select"
                      disabled={!enhancedCanPay}
                      errorMessage={
                        enhancedCanPay
                          ? payment.method !== PAYMENT_METHOD.ACH &&
                            receivingAccountErrors?.[0]
                          : undefined
                      }
                      label="Payment method"
                      value={payment.method}
                      data={paymentMethods}
                      data-testid="payment-method"
                      onChange={(value) =>
                        setPayment((previousPayment) => ({
                          ...previousPayment,
                          method: value,
                        }))
                      }
                    />

                    {billVendor.latest_ach_request &&
                      payment.method === PAYMENT_METHOD.ACH && (
                        <Flex
                          direction="column"
                          padding={["none", "none", "xl", "none"]}
                        >
                          <RequestVendorAch
                            billId={id}
                            customAchRequestAlertContent={
                              STRINGS.PENDING_ACH_REQUEST_ALERT_CONTENT
                            }
                          />
                        </Flex>
                      )}

                    <ComboBox
                      disabled={!enhancedCanPay}
                      label="Recipient"
                      value={payment.vendor}
                      data={vendors}
                      onChange={(value) =>
                        setPayment((previousPayment) => ({
                          ...previousPayment,
                          vendor: value,
                        }))
                      }
                    />

                    {payment.method === PAYMENT_METHOD.PRINT_CHECK &&
                      fromAccountComboBox}

                    {payment.method === PAYMENT_METHOD.ACH && (
                      <>
                        {recipientEmailField}
                        {fromAccountComboBox}
                        {reviewStatus !== BILL_STATUS.ACH_INFO_REQUESTED && (
                          <>
                            <ComboBox
                              disabled={!enhancedCanPay}
                              data={bankingACHS}
                              label="To account"
                              value={payment.vendorBankingACH}
                              placeholder="Will be requested"
                              onChange={(value) =>
                                setPayment((previousPayment) => ({
                                  ...previousPayment,
                                  vendorBankingACH: value,
                                }))
                              }
                            />
                            {!payment.vendorBankingACH && (
                              <Flex gap="sm">
                                <Text
                                  size="sm"
                                  color="neutral-700"
                                  weight="regular"
                                >
                                  Already have their ACH information?
                                </Text>
                                <Link
                                  as="button"
                                  type="button"
                                  variant="success"
                                  onClick={curriedOpenVendor("payments")}
                                >
                                  Enter it here
                                </Link>
                              </Flex>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {payment.method === PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK && (
                      <>
                        {recipientEmailField}
                        {fromAccountComboBox}
                        {signatureField}
                      </>
                    )}
                    {payment.method === PAYMENT_METHOD.MAIL_CHECK && (
                      <>
                        <ComboBox
                          disabled={!enhancedCanPay}
                          data={vendorAddresses}
                          label="Recipient address"
                          value={payment.vendorAddress}
                          onChange={(value) =>
                            setPayment((previousPayment) => ({
                              ...previousPayment,
                              vendorAddress: value,
                            }))
                          }
                        />
                        {fromAccountComboBox}
                        {signatureField}
                      </>
                    )}

                    {payment.method === PAYMENT_METHOD.VIRTUAL_SMS_CHECK && (
                      <>
                        <ComboBox
                          disabled={!enhancedCanPay}
                          data={vendorPhoneNumbers}
                          label="Recipient's phone number"
                          value={payment.vendorPhoneNumber}
                          onChange={(value) =>
                            setPayment((previousPayment) => ({
                              ...previousPayment,
                              vendorPhoneNumber: value,
                            }))
                          }
                        />
                        {fromAccountComboBox}
                        {signatureField}
                      </>
                    )}

                    {payment.method === PAYMENT_METHOD.MARK_AS_PAID && (
                      <ComboBox
                        disabled={!enhancedCanPay}
                        label="Mark as paid from"
                        value={payment.account}
                        data={paymentAccounts.data}
                        data-testid="mark-as-paid-from"
                        onChange={(value) =>
                          setPayment((previousPayment) => ({
                            ...previousPayment,
                            account: value,
                          }))
                        }
                      />
                    )}
                  </Flex>
                </>
              )}
            </Flex>
          )}

          {billVendor?.url && !isVendorCredit && !isInPaymentStatus && (
            <PurchaseOrders />
          )}

          <Form onEnterSubmit={onSave}>
            <Items />
          </Form>

          {/**
           * We need it as backward compatibility since old bills could not have
           * workflows attached, new bills will always have at least one workflow
           */}
          {workflows.length > 0 && (
            <ApprovalsSection
              editable={false}
              objectId={id}
              objectType="Bill"
              approvals={approvals}
              workflows={workflows}
              helperMessage={(mode) => (mode === "EMPTY" ? false : undefined)}
            />
          )}

          <Comments />
        </Flex>
      </div>

      <footer className="steps-section-footer">
        <Flex gap="xl">
          <Button variant="text" size="lg" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <DynamicActions />
        </Flex>
        <Flex gap="xl">
          <Tooltip
            message={
              permissions.canEditBill
                ? tooManyLines
                  ? STRINGS.BUTTON_TOO_MANY_LINES
                  : undefined
                : STRINGS.ACTION_NOT_ALLOWED
            }
            placement="left"
          >
            <Button
              disabled={
                isArchivedByUser || !permissions.canEditBill || tooManyLines
              }
              variant="ghost"
              size="lg"
              onClick={onSave}
            >
              Save
            </Button>
          </Tooltip>
          {getPrimaryActionButtons()}
        </Flex>
      </footer>
    </>
  );
};
