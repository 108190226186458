import React, { type ReactNode } from "react";
import { TabProvider } from "@ariakit/react";

type TabsProps = {
  value?: string;
  onChange?: (value: string) => void;
  children?: ReactNode;
};

export const Tabs = ({ value, children, onChange }: TabsProps) => {
  return (
    <TabProvider
      activeId={value}
      selectedId={value}
      setSelectedId={(value) => onChange?.(value || "")}
    >
      {children}
    </TabProvider>
  );
};
