import type {
  BudgetLineMarkupResponse,
  BudgetLinesBulkCreatePayload,
  BudgetLinesDeletePayload,
  BudgetLinesGetPayload,
  BudgetLineUpdateSchemaPayload,
  MarkupResponse,
} from "@api/budgets";
import { modifyBudgetLineSchema } from "@api/budgets/request/schema";
import {
  budgetLineMarkupsResponseSchema,
  markupsSchema,
} from "@api/budgets/response/schema";
import type {
  BudgetLineItems,
  BudgetLineItemsResponse,
  LeafBudgetLine,
} from "@api/jobs";
import { budgetLineItemsResponseSchema } from "@api/jobs/response";
import { updateBudgetLineCurrencyFields } from "@src/shared/store/jobs/thunks";
import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

const transformBudgetLineMarkupToMarkup = (
  budgetLineMarkup: BudgetLineMarkupResponse
): MarkupResponse => {
  const { markup } = budgetLineMarkup;
  return {
    id: markup?.id ?? "0",
    value: markup?.value,
    ownersValue: markup?.ownersValue,
    markupType: markup?.markupType || "percentage",
    isSeparateLine: markup?.isSeparateLine,
    budgetLine: budgetLineMarkup,
    invoicedAmount: budgetLineMarkup.invoicedAmount,
    builderAmount: budgetLineMarkup.builderAmount,
    changeAmount: budgetLineMarkup.changeAmount,
    builderRevisedAmount: budgetLineMarkup.builderRevisedAmount,
    externalChangeAmount: budgetLineMarkup.externalChangeAmount,
    ownersRevisedAmount: budgetLineMarkup.ownersRevisedAmount,
    ownersAmount: budgetLineMarkup.ownersAmount,
    jobCostMethod: budgetLineMarkup.jobCostMethod,
    sourceType: budgetLineMarkup.sourceType,
  };
};

export const budgetLinesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBudgetLines: builder.query<BudgetLineItems[], BudgetLinesGetPayload>({
      query: ({ customerId, ...params }) => {
        return {
          url: `customers/${customerId}/budgetlines/`,
          params: { ...params, markups: false },
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              data.map(transformKeysToCamelCase),
              budgetLineItemsResponseSchema
            );
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "BudgetLines" as const, id })),
              "BudgetLines",
            ]
          : ["BudgetLines"],
    }),
    getBudgetLineMarkups: builder.query<
      MarkupResponse[],
      BudgetLinesGetPayload
    >({
      query: ({ customerId }) => {
        return {
          url: `customers/${customerId}/budgetlines/`,
          params: { markups: true },
          responseHandler: async (
            response
          ): Promise<BudgetLineMarkupResponse[]> => {
            const data = await response.json();
            return handleResponse(
              data.map(transformKeysToCamelCase),
              budgetLineMarkupsResponseSchema
            );
          },
        };
      },
      transformResponse: (responseData: BudgetLineMarkupResponse[]) => {
        return handleResponse(
          responseData.map(transformBudgetLineMarkupToMarkup),
          markupsSchema
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "BudgetLines" as const, id })),
              "BudgetLines",
            ]
          : ["BudgetLines"],
    }),
    addBudgetLinesToJob: builder.mutation<
      BudgetLineItems[],
      BudgetLinesBulkCreatePayload
    >({
      query: ({ customerId, ...rest }) => {
        const body = transformKeysToSnakeCase(rest);
        return {
          url: `customers/${customerId}/budgetlines/bulk_create/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        "BudgetLines",
        "CustomerItems",
        "CustomerMarkup",
        "BudgetLineItems",
      ],
    }),
    updateBudgetLine: builder.mutation<
      LeafBudgetLine[],
      BudgetLineUpdateSchemaPayload & { path?: string }
    >({
      query: ({ customerId, budgetLineId, ...rest }) => {
        const body = transformKeysToSnakeCase(
          handleRequest({ ...rest, id: budgetLineId }, modifyBudgetLineSchema)
        );
        return {
          url: `customers/${customerId}/budgetlines/${budgetLineId}/`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(
        {
          budgetLineId,
          customerId,
          path,
          builderAmount,
          builderRevisedAmount,
          ownersAmount,
        },
        { dispatch, queryFulfilled }
      ) {
        const budgetLine = {
          id: budgetLineId,
          builderAmount,
          builderRevisedAmount,
          ownersAmount,
        };
        if (!path) return;
        const patchResult = dispatch(
          updateBudgetLineCurrencyFields({ budgetLine, path, customerId })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          dispatch(api.util.invalidateTags(["BudgetLines"]));
        }
      },
      invalidatesTags: [
        "Customer",
        "CustomerItems",
        "CustomerMarkup",
        "BudgetLines",
      ],
    }),
    deleteBudgetLine: builder.mutation<
      BudgetLineItemsResponse,
      BudgetLinesDeletePayload
    >({
      query: ({ customerId, budgetLineId }) => {
        return {
          url: `customers/${customerId}/budgetlines/${budgetLineId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        "BudgetLines",
        "CustomerItems",
        "BudgetLineItems",
        "CustomerMarkup",
      ],
    }),
  }),
});

export const {
  useAddBudgetLinesToJobMutation,
  useGetBudgetLinesQuery,
  useGetBudgetLineMarkupsQuery,
  useUpdateBudgetLineMutation,
  useDeleteBudgetLineMutation,
} = budgetLinesApi;
