export const STRINGS = {
  // common
  REQUEST_EXPIRED: "Request expired",
  EXPIRED_LINK_TITLE: "Oh no, this link has expired!",
  REQUEST_FORM_SUBMITTED_TITLE: "Thank you",
  REQUEST_ERROR_TITLE: "Oh no, something went wrong!",
  UNKNOWN_ERROR: "Unknown error occurred",
  EXPIRED_REQUEST_ALERT_TITLE: "This request for signature has expired",
  EXPIRED_REQUEST_ALERT_CONTENT:
    "Reach out to {companyName} below for details.",

  // ACH related
  LABEL_ROUTING_NUMBER: "Routing number",
  LABEL_ACCOUNT_NUMBER: "Account number",
  LABEL_CONFIRM_ACCOUNT_NUMBER: "Confirm account number",
  ACH_CONFIRMATION_DIALOG_TITLE: "Confirm ACH details?",
  ACH_CONFIRMATION_DIALOG_MESSAGE:
    "Once you send the ACH details, you won’t be able to make any further changes to them.",
  INVALID_ACCOUNT_NUMBER_ERROR: "Invalid account number",
  INVALID_ROUTING_NUMBER_ERROR: "Invalid routing number",
  CONFIRM_ACCOUNT_NUMBER_ERROR: "Confirm account number",
  ACCOUNT_NUMBERS_DONT_MATCH_ERROR: "Account numbers don't match, try again",

  // Doc related
  DOC_SUBMITTED_MESSAGE: "Thank you for providing your documents!",

  // PO related
  INVALID_SIGNATURE: "Invalid signature",
  LABEL_SIGNATURE: "Signature",
  PO_SIGNATURE_SUBMITTED_MESSAGE: "This purchase order has been signed",
  PO_SIGNATURE_FORM_BUTTON_LABEL: "Sign purchase order",
};

export const adaptiveUrl = "https://www.adaptive.build/";

export const maverickUrl = "https://www.maverickbuilders.com/";

export const VENDOR_REQUEST_TYPES = {
  ACH: "ACH",
  DOC: "DOC",
  PO_SIGNATURE: "PO_SIGNATURE",
} as const;

export const VENDOR_REQUEST_STATUSES = {
  PENDING: "PENDING",
  EXPIRED: "EXPIRED",
} as const;
