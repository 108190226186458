import React, {
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes as ReactRouterRoutes,
} from "react-router-dom";
import { Loader } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { debounce } from "@adaptive/design-system/utils";
import { Page as CardFeedPage } from "@card-feed/components";
import { GlobalSearch } from "@components/global-search";
import { Layout } from "@components/layout";
import { Logout } from "@components/logout";
import { NotFound } from "@components/not-found";
import { Shell, ShellContent } from "@components/shell";
import {
  Page as ConversationPage,
  Redirect as ConversationRedirect,
} from "@conversations/components";
import { LienWaiverSignatureRequestPage } from "@lien-waiver/components";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { Invoice as InvoiceRedirect } from "@src/invoice";
import { Invoice, InvoiceForm } from "@src/jobs/invoice";
import { TwoFactorAuthDialog } from "@src/settings/components/profile/two-factor-auth-dialog";
import {
  BasePermissionCategory,
  BasePermissions,
  useClientSettings,
  useUserInfo,
} from "@store/user";
import cn from "clsx";

import { BillForm, Bills } from "../bills";
import { Expense } from "../expenses/expense";
import { Expenses, ExpensesTablePage } from "../expenses/expenses";
import { Job, JobDetailPage, Jobs, JobsTablePage } from "../jobs";
import { PurchaseOrders } from "../purchase-orders";
import { Reports } from "../reports";
import { Settings } from "../settings";
import { Vendors } from "../vendors";

import { Dresser } from "./drawers";
import { ProtectedRoute } from "./protected-route";
import { Sidebar } from "./sidebar";

const IS_FIXED_EXPANDED_KEY = "sidebar-is-fixed-expanded";

const Structure = memo(({ children }: { children: ReactNode }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const [isFixedExpanded, setIsFixedExpanded] = useState<true | undefined>(
    () => {
      if (window.IS_E2E) return true;

      return (
        localStorage.getItem(IS_FIXED_EXPANDED_KEY) === "true" || undefined
      );
    }
  );

  const enhancedIsCollapsed = isFixedExpanded ? false : isCollapsed;

  const debouncedSetIsCollapsed = useMemo(
    () => debounce((isCollapsed: boolean) => setIsCollapsed(isCollapsed), 100),
    []
  );

  const open = useEvent(() => debouncedSetIsCollapsed(false));

  const close = useEvent(() => debouncedSetIsCollapsed(true));

  const pin = useEvent(() => {
    setIsFixedExpanded(true);
    localStorage.setItem(IS_FIXED_EXPANDED_KEY, "true");
  });

  const unpin = useEvent(() => {
    setIsFixedExpanded(undefined);
    localStorage.removeItem(IS_FIXED_EXPANDED_KEY);
    close();
  });

  return (
    <Shell
      className={cn("app-shell", {
        "-fixed": isFixedExpanded,
        "-collapsed": enhancedIsCollapsed,
      })}
    >
      <Sidebar
        pin={pin}
        open={open}
        close={close}
        unpin={unpin}
        isCollapsed={enhancedIsCollapsed}
        isFixedExpanded={isFixedExpanded}
      />
      <ShellContent>{children}</ShellContent>
    </Shell>
  );
});

Structure.displayName = "Structure";

const Index = memo(() => (
  <Structure>
    <Outlet />
  </Structure>
));

Index.displayName = "Index";

const DefaultRoute = memo(() => {
  const [to, setTo] = useState("");

  const [state, setState] = useState("idle");

  const { hasPermission, hasViewAllJobsPermission } = useUserInfo();

  const inferInitialRoute = useCallback(async () => {
    let to = "";

    if (hasPermission(BasePermissions.VIEW_ALL_BILLS)) {
      to = "/bills";
    } else if (hasPermission(BasePermissions.VIEW_ALL_EXPENSES)) {
      to = "/expenses";
    } else if (hasPermission(BasePermissions.VIEW_ALL_POS)) {
      to = "/purchase-orders";
    } else if (hasPermission(BasePermissions.VIEW_ALL_VENDORS)) {
      to = "/vendors";
    } else if (await hasViewAllJobsPermission()) {
      to = "/jobs";
    }

    setTo(to);
    setState(to ? "success" : "failed");
  }, [hasPermission, hasViewAllJobsPermission]);

  useEffect(() => {
    inferInitialRoute();
  }, [inferInitialRoute]);

  if (state === "idle") return <Loader position="absolute" />;

  if (state === "failed") {
    return (
      <Layout
        title="404 Page not found"
        subtitle="You don't have permission to access Adaptive."
      />
    );
  }

  return <Navigate to={to} />;
});

DefaultRoute.displayName = "DefaultRoute";

const Routes = withSentryReactRouterV6Routing(ReactRouterRoutes);

export const App = memo(() => {
  const { cardFeedEnabled } = useClientSettings();

  const { getPermissionsFromCategory, hasViewAllJobsPermission, user } =
    useUserInfo();

  if (user.type === "external") {
    return (
      <Routes>
        <Route path=":type/:id/conversation" element={<ConversationPage />} />
        <Route
          path="vendor-lien-waiver-request/:realmUid/:lienWaiverUid"
          element={<LienWaiverSignatureRequestPage />}
        />
        <Route path="*" element={<Logout />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Index />}>
          <Route element={<DefaultRoute />} path="" />
          <Route
            path="expenses"
            element={
              <ProtectedRoute
                somePermissionsOf={getPermissionsFromCategory(
                  BasePermissionCategory.EXPENSES
                )}
              >
                <Expenses />
              </ProtectedRoute>
            }
          >
            <Route index element={<ExpensesTablePage />} />
            <Route path=":expenseId" element={<Expense />} />
          </Route>
          <Route
            path="jobs"
            element={
              <ProtectedRoute validate={hasViewAllJobsPermission}>
                <Jobs />
              </ProtectedRoute>
            }
          >
            <Route index element={<JobsTablePage />} />
            <Route path=":jobId" element={<Job />}>
              <Route index element={<JobDetailPage />} />
              <Route path="invoices/:invoiceId" element={<Invoice />}>
                <Route index element={<InvoiceForm />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="bills"
            element={
              <ProtectedRoute
                somePermissionsOf={getPermissionsFromCategory(
                  BasePermissionCategory.BILLS
                )}
              >
                <Bills />
              </ProtectedRoute>
            }
          />
          {cardFeedEnabled && (
            <Route
              path="card-feed"
              element={
                <ProtectedRoute
                  somePermissionsOf={getPermissionsFromCategory(
                    BasePermissionCategory.EXPENSES
                  )}
                >
                  <CardFeedPage />
                </ProtectedRoute>
              }
            />
          )}
          <Route
            path="bills/:id"
            element={
              <ProtectedRoute
                somePermissionsOf={getPermissionsFromCategory(
                  BasePermissionCategory.BILLS
                )}
              >
                <BillForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="purchase-orders/*"
            element={
              <ProtectedRoute
                somePermissionsOf={getPermissionsFromCategory(
                  BasePermissionCategory.POS
                )}
              >
                <PurchaseOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="vendors/*"
            element={
              <ProtectedRoute
                somePermissionsOf={getPermissionsFromCategory(
                  BasePermissionCategory.VENDORS
                )}
              >
                <Vendors />
              </ProtectedRoute>
            }
          />
          <Route
            path="reports/*"
            element={
              <ProtectedRoute
                somePermissionsOf={[BasePermissions.VIEW_ALL_REPORTS]}
              >
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route path="invoices/:invoiceId" element={<InvoiceRedirect />} />
          <Route path="settings/*" element={<Settings />} />
          <Route
            path=":type/:id/conversation"
            element={<ConversationRedirect />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Dresser />
      <GlobalSearch />
      <TwoFactorAuthDialog />
    </>
  );
});

App.displayName = "App";
