import React from "react";
import { forwardRefAs } from "@adaptive/design-system/utils";
import cn from "clsx";

import styles from "./main.module.css";

const DEFAULT_COMPONENT = "header";

type Props = {
  size?: "sm" | "md" | "lg";
  variant?: "spaced" | "unspaced";
};

export const MainHeader = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      size = "md",
      variant = "spaced",
      className,
      as: Component = DEFAULT_COMPONENT,
      ...props
    },
    ref
  ) => (
    <Component
      ref={ref}
      className={cn(className, styles["main-header"], {
        [styles[`-${size}`]]: size,
        [styles[`-${variant}`]]: variant,
      })}
      {...props}
    />
  )
);
